<template>
    <div class="section solutions">
        <div class="section__content">
            <div v-if="!isMobile" class="solutions__ripples" :style="{ 
                backgroundImage: 'url(' + require(`@/assets/img/black${slideId}.jpg`) + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center' }">
            </div>
            <div class="section__left">
                <h2 class="typography typography--h2 section__title">
                    <span v-if="!isMobile" class="white">{{ title.white }} </span>
                    <span v-if="!isMobile" class="red">{{ title.red }}</span>

                    <span v-if="isMobile" class="white">{{ this.$t('sections.solutions.Our') }} </span>
                    <span v-if="isMobile" class="red">{{ this.$t('sections.solutions.solutions') }}</span>

                    <div v-if="!isMobile" class="solutions__bookmarks">
                        <img src="@/assets/img/bookmark.svg" alt="bookmark">
                        <span v-if="slideId == 5">Event</span>
                        <span v-else>Product</span>
                    </div>
                </h2>
                
            </div>
            <div class="section__right">
                <div v-if="!isMobile" class="solutions__content">
                    <div class="solutions__one" v-if="slideId == 1">
                        <h4 class="typography typography--h4 solutions__contentTitle">{{ $t('sections.solutions.About solution1 caption') }}</h4>
                        <p class="typography typography--paragraph">{{ $t('sections.solutions.Solution1 description') }}
                            <br />
                            <br />
                            <localized-link :to="{ name: 'Solution', params: { id: 1, name: 'patentem' } }" class="typography solutions__moreLink">See more</localized-link>
                        </p>
                    </div>
                    <div class="solutions__one" v-if="slideId == 2">
                        <h4 class="typography typography--h4 solutions__contentTitle">{{ $t('sections.solutions.About solution2 caption') }}</h4>
                        <p class="typography typography--paragraph">{{ $t('sections.solutions.Solution2 description') }}
                            <br />
                            <br />
                            <localized-link :to="{ name: 'Solution', params: { id: 2, name: 'elect-pro' } }" class="typography solutions__moreLink">See more</localized-link>
                        </p>
                    </div>
                    <div class="solutions__one" v-if="slideId == 3">
                        <h4 class="typography typography--h4 solutions__contentTitle">{{ $t('sections.solutions.About solution3 caption') }}</h4>
                        <p class="typography typography--paragraph">{{ $t('sections.solutions.Solution3 description') }}
                            <br />
                            <br />
                            <localized-link :to="{ name: 'Solution', params: { id: 3, name: 'scientia' } }" class="typography solutions__moreLink">See more</localized-link>
                        </p>
                    </div>
                    <div class="solutions__one" v-if="slideId == 4">
                        <h4 class="typography typography--h4 solutions__contentTitle">{{ $t('sections.solutions.About solution4 caption') }}</h4>
                        <p class="typography typography--paragraph">{{ $t('sections.solutions.Solution4 description') }}
                            <br />
                            <br />
                            <localized-link :to="{ name: 'Solution', params: { id: 4, name: 'bus-station' } }" class="typography solutions__moreLink">See more</localized-link>
                        </p>
                    </div>
                    <div class="solutions__one" v-if="slideId == 5">
                        <h4 class="typography typography--h4 solutions__contentTitle">{{ $t('sections.solutions.About solution5 caption') }}</h4>
                        <p class="typography typography--paragraph">{{ $t('sections.solutions.Solution5 description') }}
                            <br />
                            <br />
                            <localized-link :to="{ name: 'Solution', params: { id: 5, name: 'digitalization-of-politics' } }" class="typography solutions__moreLink">See more</localized-link>
                        </p>
                    </div>
                </div>

                <div v-if="!isMobile" class="grabbing-example">
                    <span class="grabbing-rectangle-gray"></span>
                    <span class="grabbing-rectangle-gray">
                        <span class="grabbing-rectangle-red">
                            <span class="grabbing-cursor"></span>
                        </span>
                    </span>
                    <span class="grabbing-rectangle-gray"></span>
                    <span class="grabbing-rectangle-gray"></span>
                </div>
                

                <VueSlickCarousel class="solutions__slider" v-bind="settings">
                    <div @click="itemClick(1, 'patentem')" class="solutions__sliderItem solutions__sliderItem--2"
                        :style="{ backgroundImage: 'url(' + require(`@/assets/img/black1.jpg`) +')'}">
                        <span>{{ solutions[0] }}</span>
                        <img v-if="!isMobile" class="solutions__sliderFinger" src="@/assets/img/finger.png" alt="png">
                    </div>
                    <div @click="itemClick(2, 'elect-pro')" class="solutions__sliderItem solutions__sliderItem--3"
                        :style="{ backgroundImage: 'url(' + require(`@/assets/img/black2.jpg`) +')'}">
                        <span>{{ solutions[1] }}</span>
                        <img v-if="!isMobile" class="solutions__sliderFinger" src="@/assets/img/finger.png" alt="png">
                    </div>
                    <div @click="itemClick(3, 'scientia')" class="solutions__sliderItem solutions__sliderItem--4"
                        :style="{ backgroundImage: 'url(' + require(`@/assets/img/black3.jpg`) +')'}">
                        <span>{{ solutions[2] }}</span>
                        <img v-if="!isMobile" class="solutions__sliderFinger" src="@/assets/img/finger.png" alt="png">
                    </div>
                    <div @click="itemClick(4, 'bus-station')" class="solutions__sliderItem solutions__sliderItem--5"
                        :style="{ backgroundImage: 'url(' + require(`@/assets/img/black4.jpg`) +')'}">
                        <span>{{ solutions[3] }}</span>
                        <img v-if="!isMobile" class="solutions__sliderFinger" src="@/assets/img/finger.png" alt="png">
                    </div>
                    <div @click="itemClick(5, 'digitalization-of-politics')" class="solutions__sliderItem solutions__sliderItem--5"
                        :style="{ backgroundImage: 'url(' + require(`@/assets/img/black5.jpg`) +')'}">
                        <span>{{ solutions[4] }}</span>
                        <img v-if="!isMobile" class="solutions__sliderFinger" src="@/assets/img/finger.png" alt="png">
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
    </div>
</template>

<script>
/* Waves */
import "@/plugins/jquery.ripples.js"

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {

    components: {
        VueSlickCarousel
    },

    data() {
        return {
            settings: {
                infinite: true,
                arrows: false,
                "focusOnSelect": true,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: false
            },
            slideId: 1,
            title: {
                white: '',
                red: this.$t('sections.solutions.Solution1'),
            },
            solutions: []
        }
    },

    mounted() {
        this.settings.centerMode = this.isMobile;
        if (this.isMobile) {
            this.settings.slidesToShow = 2;
        }
        
    },

    created() {
        this.solutions = [this.$t('sections.solutions.Solution1'), this.$t('sections.solutions.Solution2'), this.$t('sections.solutions.Solution3'), this.$t('sections.solutions.Solution4'), this.$t('sections.solutions.Solution5')]
    },

    methods: {
        itemClick(id, routeName) {
            if (this.isMobile) {
                let solutionId = id
                this.$router.push({ name: 'Solution', params: { id: solutionId, name: routeName } }).catch(() => {})
            }
            if (this.slideId == id || this.isMobile) {
                return false
            }

            //$('.solutions__ripples').addClass('solutions__ripples--updating')
            setTimeout(() => {
                //$('.solutions__ripples').ripples('destroy')
                this.slideId = id

                this.title = {white: '',red: this.solutions[id-1]};

                /* setTimeout(() => {
                    $('.solutions__ripples').ripples();
                    $('.solutions__ripples').removeClass('solutions__ripples--updating')
                }, 200); */
            }, 200);
        }
    },

};
</script>


<style lang="scss" scoped>
@import "@/assets/scss/grabbing-example.scss";

.solutions {
    position: relative;
    z-index: 10;

    @media screen and (max-width: $lg) {
        padding-bottom: 350px;
        background-color: $color_black;
        padding-top: 60px;
    }

    @media screen and (max-width: $sm) {
        padding-bottom: 300px;
    }

    &__ripples {
        height: 100%;
        width: 50vw;
        position: absolute;
        background-position: center;
        background-size: 100%;
        transition: .3s;
        background-color: $color_black;

        @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 900px) {
            height: 100vh;
        }

        &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: #01000182;
            transition: .2s;
            left: 0;
            top: 0;
        }

        &--updating {
            &:before {
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: $color_black;
                left: 0;
                top: 0;
                transition: .3s;
            }
        }
    }

    &__moreLink {
        border: none;
        border-radius: 2px;
        color: white;
        text-align: center;
        font-family: $ff-primary;
        letter-spacing: 2px;
        font-size: 20px;
        height: max-content;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .2s;
        font-weight: 400;
        padding: 17px 20px;
        width: 240px;
        max-width: 100%;
        text-align: center;
        background-color: $color_red;

        &:hover {
            color: white;
            box-shadow: 0 8px 12px -6px rgba(255, 25, 61, 0.4);
        }

        &:focus {
            outline: none;
        }

        @media screen and (max-width: $xxl) and (max-height: 900px) and (min-width: 1200px) and (min-height: 595px) {
            width: 210px;
            padding: 12px 18px;
            font-size: 16px;
            box-sizing: border-box;
        }

        @media screen and (min-width: 1200px) and (min-height: 600px) {
            margin-top: auto;
        }
    }

    &__slider {
        top: 4px;
    }

    &__sliderFinger {
        position: absolute;
        top: calc(50% - 30px);
        left: calc(50% - 20px);
        transform: translate(-50%, -50%);
        height: 60px;
        display: none !important;
        z-index: 10;
        animation: pulsate 2.7s ease-out;
        animation-iteration-count: infinite;

        @keyframes pulsate {
          0% {
            transform: scale(0.5, 0.5);
            opacity: 0.2;
          }
          50% {
            opacity: 1;
            transform: scale(1, 1);
          }
          100% {
            transform: scale(0.5, 0.5);
            opacity: 0.2;
          }
        }
    }

    &__content {
        padding: 140px 12vw 40px 4vw;
        margin-top: auto;
        position: relative;

        @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 900px) {
            padding-top: 0;
        }
    }

    &__bookmarks {
        background-color: #3438397d;
        padding: 11px 15px;
        color: white;
        border-radius: 4px;
        display: flex;
        align-items: center;
        z-index: 222;
        position: absolute;
        width: fit-content;
        font-size: 12px;

        img {
            margin-right: 10px;
            height: 20px;
        }
    }

    &__contentTitle {
        color: $color_red;
    }

    &__one {

        p {
            @media screen and (max-width: $xxl) {
                font-size: 16px;
            }

            @media screen and (min-width: 1200px) and (min-height: 690px) {
                min-height: 275px;
                display: flex;
                flex-direction: column;
            }

            @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 689px) {
                display: flex;
                flex-direction: column;
                min-height: 205px;
            }
        }
    }

    &__sliderItem {
        height: 300px;
        width: 100px;
        background-position: center;
        background-size: cover;
        position: relative;
        cursor: grab;

        &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: #36171899;
            left: 0;
            transition: .1s;
            top: 0;
        }

        &:hover {
            &:before {
                background-color: #b5102973;
            }
        }

        span {
            position: absolute;
            bottom: 40px;
            left: 20px;
            color: white;
            z-index: 12;
            font-weight: 900;
            font-size: 20px;
            transition: .2s;

            @media screen and (max-width: $sm) {
                bottom: 20px;
                font-size: 11px;
            }

            @media screen and (max-width: $xxl) and (max-height: 900px) and (min-width: 1200px) and (min-height: 595px) {
                font-size: 14px;
                bottom: 20px;
            }
        }

        @media screen and (max-width: $xxl) and (max-height: 900px) and (min-width: 1200px) and (min-height: 595px) {
            height: 160px;
        }

        @media screen and (max-width: $lg) {
            height: 300px;
        }

        @media screen and (max-width: $sm) {
            height: 200px;
        }
    }

    .section__right {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: $lg) {
            width: 100vw;
            position: absolute;
            margin-top: 120px;
        }

        @media screen and (max-width: $sm) {
            margin-top: 120px;
        }
    }

    .section__left {
        @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 900px) {
            padding-top: 70px;
        }
    }
}
</style>

<style lang='scss'>
    .slick-current + .slick-active {
        .solutions__sliderFinger {
            display: block !important;
        }
    }
</style>