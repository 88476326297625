<template>
    <div class="navigation">
        <header class="header">
            <div class="header__content">
                <localized-link to="/">
                    <img ref="header__logo" class="header__logo" src="@/assets/img/logo_small.svg" alt="svg">
                </localized-link>
                <div v-if="isMobile && backButton" @click="goBack" class="header__back">
                    <img src="@/assets/img/back.svg" alt="svg">
                </div>
                <div class="header__presentation">
                    <ButtonComponent 
                        class="header__presentationIcon" 
                        :icon="`assets/img/presentation.svg`" 
                        :caption="$t('navigation.Presentation')" 
                        :to="'/static/presentation.pdf'"
                    />
                </div>
                <div class="header__menu" @click="toggleMenu">
                    <div class="header__menuLines"><span></span><span></span><span></span></div>
                </div>
            </div>
        </header>

        <!-- <div class="navigation__contactUsButton" :class="{'navigation__contactUsButton--hide' : navContent}">
            <img src="@/assets/img/mail.svg" alt="svg">
        </div> -->

        <div class="navigation__content" :class="{'navigation__content--show' : navContent}">

            <div class="navigation__contentColumn">
                <img class="navigation__contentLogo" src="@/assets/img/logo_small.svg" alt="svg">
                <localized-link to="/#main" @click.native="toggleMenu" class="navigation__contentColumnLink">{{ $t('navigation.main') }}</localized-link>
                <localized-link to="/#about" @click.native="toggleMenu" class="navigation__contentColumnLink">{{ $t('navigation.about 3k') }}</localized-link>
                <localized-link to="/#services" @click.native="toggleMenu" class="navigation__contentColumnLink">{{ $t('navigation.services') }}</localized-link>
                <localized-link to="/#solutions" @click.native="toggleMenu" class="navigation__contentColumnLink">{{ $t('navigation.solutions') }}</localized-link>
                <localized-link to="/#vacancies" @click.native="toggleMenu" class="navigation__contentColumnLink">{{ $t('navigation.vacancies') }}</localized-link>
                <localized-link to="/#customers" @click.native="toggleMenu" class="navigation__contentColumnLink">{{ $t('navigation.customers') }}</localized-link>
                <localized-link to="/#contacts" @click.native="toggleMenu" class="navigation__contentColumnLink">{{ $t('navigation.contacts') }}</localized-link>
            </div>
            <!-- <div class="navigation__contentColumn">
                <localized-link to="/" @click.native="toggleMenu" class="navigation__contentColumnLinkMain">{{ $t('navigation.presentations') }}</localized-link>
                <h4 @click="columnTitleClick(1)" class="typography typography--h4 navigation__contentColumnTitle" :class="{ 'open' : firstDropdownActive }">{{ $t('navigation.services') }}:</h4>
                <localized-link to="/service/consulting" @click.native="toggleMenu" class="navigation__contentColumnLinkSub" :class="{'active' : firstDropdownActive}">{{ $t('navigation.Consulting') }}</localized-link>
                <localized-link to="/service/business" @click.native="toggleMenu" class="navigation__contentColumnLinkSub" :class="{'active' : firstDropdownActive}">{{ $t('navigation.Business process analysis') }}</localized-link>
                <localized-link to="/service/audit" @click.native="toggleMenu" class="navigation__contentColumnLinkSub" :class="{'active' : firstDropdownActive}">{{ $t('navigation.Audit') }}</localized-link>
                <localized-link to="/service/development" @click.native="toggleMenu" class="navigation__contentColumnLinkSub" :class="{'active' : firstDropdownActive}">{{ $t('navigation.Development') }}</localized-link>
                <localized-link to="/service/integration" @click.native="toggleMenu" class="navigation__contentColumnLinkSub" :class="{'active' : firstDropdownActive}">{{ $t('navigation.Integrations') }}</localized-link>
                <localized-link to="/service/apps" @click.native="toggleMenu" class="navigation__contentColumnLinkSub" :class="{'active' : firstDropdownActive}">{{ $t('navigation.Mobile applications') }}</localized-link>
            </div> -->
            <div class="navigation__contentColumn">
                <localized-link style="visibility: hidden" to="/" @click.native="toggleMenu" class="navigation__contentColumnLinkMain">{{ $t('navigation.videos') }}</localized-link>
                <h4 @click="columnTitleClick(2)" class="typography typography--h4 navigation__contentColumnTitle" :class="{ 'open' : secondDropdownActive }">{{ $t('navigation.solutions') }}:</h4>
                <localized-link :to="{ name: 'Solution', params: { id: 1, name: 'patentem' } }" @click.native="toggleMenu" class="navigation__contentColumnLinkSub" :class="{'active' : secondDropdownActive}">Patentem</localized-link>
                <localized-link :to="{ name: 'Solution', params: { id: 2, name: 'elect-pro' } }" @click.native="toggleMenu" class="navigation__contentColumnLinkSub" :class="{'active' : secondDropdownActive}">ElectPro</localized-link>
                <localized-link :to="{ name: 'Solution', params: { id: 3, name: 'scientia' } }" @click.native="toggleMenu" class="navigation__contentColumnLinkSub" :class="{'active' : secondDropdownActive}">Scientia</localized-link>
                <localized-link :to="{ name: 'Solution', params: { id: 4, name: 'bus-station' } }" @click.native="toggleMenu" class="navigation__contentColumnLinkSub" :class="{'active' : secondDropdownActive}">Bus station management system</localized-link>
                <localized-link :to="{ name: 'Solution', params: { id: 5, name: 'digitalization-of-politics' } }" @click.native="toggleMenu" class="navigation__contentColumnLinkSub" :class="{'active' : secondDropdownActive}">{{ this.$t('sections.solutions.Solution5') }}</localized-link>
            </div>

            <!-- <language-switcher class="navigation__langs" v-slot="{ links }">
              <router-link @click.native="changeLocale" v-for="link in links" :key="link.langIndex" :to="link.url">
                <span>{{ link.langName }}</span>
              </router-link>
            </language-switcher> -->
        </div>
    </div>
    
</template>

<script>
export default {

    data() {
        return {
            navContent: false,
            menuClick: false,
            firstDropdownActive: false,
            secondDropdownActive: false
        }
    },

    computed: {
        backButton() {
            return this.$route.name !== "Home"
        }
    },

    mounted() {
        let body = document.getElementsByTagName('body')[0];

        setTimeout(() => {
            if (body.classList.contains('nav-active')) {
                body.classList.remove('nav-active')
                console.log('sdf');
                this.navContent = false
                this.menuClick = false
            }
        }, 0);

        body.clientWidth <= 992 ? this.$store.commit('setResolution', 'mobile') : this.$store.commit('setResolution', 'desktop')
        document.addEventListener('keydown', this.keydownEvent);
    },

    watch:{
        $route (to, from){
            if (to.hash) {
                let fullpageAnchor = '';
                try {
                    fullpageAnchor = to.hash.slice(1);
                    this.$parent.$refs.fullpage.api.moveTo(fullpageAnchor, 1);
                } catch (error) {}
            }
        }
    },

    beforeDestroy() {
        document.removeEventListener('keydown', this.keydownEvent)
    },

    methods: {
        goBack() {
            if (this.$route.name == 'CustomerMobile') {
                this.$router.push({ name: 'Home' }).then(() => {
                    document.querySelector(`#customers`).scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                }).catch(e => {})
            }
            else if (this.$route.name == 'Customer') {
                this.$router.push({ name: 'CustomerMobile', params: { name: this.$route.params.name } }).catch(e => {})
            }
            else {
                this.$router.push({ name: 'Home' }).then(() => {
                    document.querySelector(`#solutions`).scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                }).catch(e => {})
            }
            
        },
        toggleMenu(e) {
            if (this.menuClick) {return false}

            if (e.target.classList.contains('navigation__contentColumnLink') && this.isMobile) {
                let id = e.target.attributes.href.value.substring(2);

                document.querySelector(`#${id}`).scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
           
            this.menuClick = true
            let body = document.querySelector('body');

            if (body.classList.contains('nav-active')) {
                if (this.$parent.$refs?.fullpage) {
                    this.$parent.$refs.fullpage.api.setAllowScrolling(true);
                }
                body.classList.remove('nav-active')
                this.navContent = false
                this.menuClick = false
            }
            else {
                if (this.$parent.$refs?.fullpage) {
                    this.$parent.$refs.fullpage.api.setAllowScrolling(false);
                }

                body.classList.add('nav-active')
                
                setTimeout(() => {
                    this.navContent = true
                    this.menuClick = false
                }, 500)
            }
        },

        keydownEvent(e) {
            if(e.keyCode === 27) {
                let body = document.querySelector('body');
                if (body.classList.contains('nav-active')) {
                    if (this.$parent.$refs?.fullpage) {
                        this.$parent.$refs.fullpage.api.setAllowScrolling(true);
                    }
                    body.classList.remove('nav-active')
                    this.navContent = false
                    this.menuClick = false
                }
            }
        },

        columnTitleClick(v) {
            v == 1 ? this.firstDropdownActive = !this.firstDropdownActive : this.secondDropdownActive = !this.secondDropdownActive
        },

        changeLocale() {
            this.$emit('changeLocale')
        }
    }

}
</script>

<style lang="scss" scoped>

    .navigation {
        position: relative;
        z-index: 1200;
        width: 100%;
        display: flex;
        flex-direction: column;

        &::before, &::after {
            content: "";
            position: fixed;
            width: 100vw;
            height: 100vh;
            background: $color_red;
            border-bottom-left-radius: 200%;
            z-index: -1;
            transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.8s;
            transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.8s;
            transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.8s;
            transform: translateX(100%) translateY(-100%);
        }

        &__content {
            display: none;
            position: relative;
            justify-content: center;
            height: 100%;
            width: 100%;
            overflow-x: hidden;
            transition: .3s;

            &--show {
                display: flex;
            }

            @media screen and (max-width: $lg) {
                flex-direction: column;
                height: max-content;
            }

            @media screen and (max-width: $sm) {
                padding-bottom: 50px;
            }
        }

        &__contactUsButton {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: $color_red;
            border-radius: 50%;
            height: 50px;
            width: 50px;
            transition: .2s;
            position: fixed;
            left: 40px;
            bottom: 40px;
            z-index: 4;
            

            @media screen and (max-width: $lg) {
                left: 20px;
                bottom: 20px;

                &--hide {
                    display: none;
                }
            }
        }

        &__langs {
            position: absolute;
            right: 12vw;
            top: 57px;
            z-index: 4;

            @media screen and (max-width: $lg) {
                top: 36px;
                right: auto;
                left: 12vw;
            }

            a {
                color: white;
                text-transform: uppercase;
                font-size: 15px;
            }

            a + a {
                border-left: 1px solid white;
                margin: 0 10px;
                padding: 0 10px;
            }
        }

        &__contentColumn {
            width: calc(100%/3);
            padding: 0 100px;
            padding-top: 17vh;
            display: flex;
            flex-direction: column;

            
            @media screen and (max-width: $xxl) {
                padding: 0 60px;
                padding-top: 17vh;
            }

            @media screen and (max-width: $lg) {
                padding-top: 0;
                 padding: 0 12vw;
            }

            @media screen and (max-width: $sm) {
                width: 100%;
            }

            &:nth-child(1) {
                padding-left: 12vw;

                @media screen and (max-width: $lg) {
                    padding-top: 17vh;
                    margin-bottom: 40px;
                }
            }

            &:nth-child(2) {
                padding-right: 12vw;
                border-left: 1px solid #ffffff63;
            }

           /*  &:nth-child(2) {
                border-left: 1px solid #ffffff63;
                border-right: 1px solid #ffffff63;

                @media screen and (max-width: $lg) {
                    border: none;
                }
            }

            &:nth-child(3) {
                padding-right: 12vw;
            } */
        }

        &__contentColumnLink {
            font-size: 3vw;
            color: white;
            letter-spacing: 0.77px;
            font-weight: 900;

            @media screen and (max-width: $xl) {
                font-size: 22px;
            }

            @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
                font-size: 22px;
            }

            & + & {
                margin-top: 30px;
            }
        }

        &__contentColumnTitle {
            color: white;
            margin-bottom: 40px;
            text-transform: lowercase;
            position: relative;
            font-size: 46px;

            @media screen and (max-width: $xl) {
                font-size: 22px;
            }

            @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
                font-size: 22px;
            }

            @media screen and (max-width: $lg) {
                color: white;
                letter-spacing: 0.77px;
                font-weight: 900;
                text-transform: none;
                font-size: 22px;
                cursor: url('~@/assets/img/cursor_filled.svg') 0 0, pointer !important;

                &:before {
                    content: '';
                    position: absolute;
                    border: 6px solid transparent;
                    border-left: 6px solid white;
                    left: -10px;
                    border-radius: 3px;
                    top: 7px;
                    transition: .3s;
                }

                &.open {
                    &:before {
                        content: '';
                        position: absolute;
                        border: 6px solid transparent;
                        border-top: 6px solid white;
                        left: -14px;
                        border-radius: 3px;
                        top: 10px;
                    }
                }
            }
        }

        &__contentColumnLinkMain {
            letter-spacing: 2.78px;
            color: white;
            font-size: 32px;
            margin-bottom: 100px;
            text-transform: lowercase;
            text-decoration: underline;

            @media screen and (max-width: $xl) {
                font-size: 22px;
            }

            @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
                font-size: 22px;
                margin-bottom: 77px;
            }

            @media screen and (max-width: $lg) {
                display: none;
            }
        }

        &__contentColumnLinkSub {
            letter-spacing: 1.5px;
            font-size: 24px;
            color: white;
            text-decoration: underline;
            text-transform: lowercase;

            @media screen and (max-width: $xl) {
                font-size: 18px;
            }

            @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
                font-size: 18px;
            }

            @media screen and (max-width: $lg) {
                display: none;

                &.active {
                    display: block;
                }

                &:last-child {
                    margin-bottom: 40px;
                }
            }

            & + & {
                margin-top: 30px;
            }

            @media screen and (max-width: $sm) {
                max-width: 70vw;
            }
        }

        &__contentLogo {
            height: 45px;
            width: fit-content;
            margin-bottom: 90px;
            filter: brightness(0) invert(1);

            @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
                margin-bottom: 49px;
            }

            @media screen and (max-width: $xl) {
                margin-bottom: 81px;
            }
        }

        &__menu {
            width: 100%;
        }
    }
    
    .header {
        position: absolute;
        width: 100%;
        z-index: 2;
        background-color: transparent;

        @media screen and (max-width: $lg) {
            position: fixed;
            left: 0;
            top: 0;
        }

        &__content {
            width: calc(100% - 80px);
            background-color: transparent;
            display: flex;
            justify-content: space-between;
            padding: 40px;

            @media screen and (max-width: $lg) {
                width: calc(100% - 40px);
                padding: 20px;
            }
        }

        &__back {
            height: 60px;
            width: 60px;
            background-color: $color_red;
            left: 20px;
            top: 75px;
            position: absolute;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                filter: invert(1);
            }
        }

        &__logo {
            height: 0;
            opacity: 0;
            transition: .3s;
        }
        
        &__menu {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: $color_red;
            border-radius: 50%;
            height: 60px;
            width: 60px;
            transition: .2s;

            @media screen and (max-width: 1400px) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
                height: 50px;
                width: 50px;
            }

            @media screen and (max-width: $lg) {
                height: 50px;
                width: 50px;
            }
        }

        &__menuLines {
            opacity: 1;
            transition: opacity .3s;

            span {
                display: block;
                width: 20px;
                height: 2px;
                transition: width .3s;
                margin: 4px 0;
                border-radius: 2px;
                background-color: white;
            }
        }

        &__presentation {
            margin-left: auto;
            margin-right: 20px;

            @media screen and (max-width: $sm) {
                width: calc(100% - 70px);
            }
        }

        &__presentationIcon {
            @media screen and (max-width: $sm) {
                width: initial;
            }
        }
    }

    
</style>

<style lang="scss">
    body.nav-active {
        height: 100vh !important;
        overflow: hidden !important;
        
        .navigation {
            position: fixed;
            height: 100vh;
            top: 0;
            z-index: 100000;

            @media screen and (max-width: $lg) {
                overflow: auto;
            }
        }

        .header__logo {
            opacity: 0 !important;
        }
    }
    .nav-active .navigation:before, .nav-active .navigation:after {
        transform: translateX(0%) translateY(0%);
        border-radius: 0;
    }
</style>
