<template>
    <div>
        <transition name="fade">
            <Preloader v-if="preloader" />
        </transition>

        <Navigation ref="navigation" @changeLocale="changeLocale" />
        
        <div class="section one-solution" :key="remountKey">
            <div class="section__content">
                <div class="one-solution__ripples" :style="{ 
                    backgroundImage: 'url(' + require(`@/assets/img/black${solutionId}.jpg`) + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center' }">
                </div>
                <div class="section__left">
                    <h2 class="typography typography--h2 section__title">
                        <span class="red">{{ title }}</span>
                    </h2>

                     <div class="one-solution__bookmarks">
                        <img src="@/assets/img/bookmark.svg" alt="bookmark">
                        <span v-if="solutionId == 5">Event</span>
                        <span v-else>Product</span>
                    </div>
                    
                </div>
                <div class="section__right">
                    <div class="one-solution__content">
                        <div class="one-solution__one" v-if="solutionId == 1">
                            <p class="typography typography--paragraph"><b>Complex information system for sessions, meetings and voting that complies to high standards and requirements of the public and corporate sector.</b></p>
                            <p class="typography typography--paragraph">System is designed to automate the process of preparations and holding of online, offline and mixed meetings, conferences, congresses, sessions and other events types.</p>

                            <p class="typography typography--paragraph"><b>Wide integration capabilities:</b></p>
                            <ul class="base">
                                <li>
                                    Video conferencing systems
                                    <ul class="base--inner">
                                        <li>Cisco meeting server</li>
                                        <li>Trueconf</li>
                                        <li>Zoom</li>
                                    </ul>
                                </li>
                                <li>
                                    Сonference systems:
                                    <ul class="base--inner">
                                        <li>Bosch DCN NG and Dicentis</li>
                                        <li>Televic</li>
                                    </ul>
                                </li>
                                <li>
                                    Lifted monitors:
                                    <ul class="base--inner">
                                        <li>Soltec</li>
                                        <li>Arthur Holm</li>
                                        <li>Element One</li>
                                    </ul>
                                </li>
                                <li>Access control systems</li>
                                <li>Active Directory</li>
                                <li>Microsoft exchange</li>
                                <li>Document management systems</li>
                            </ul>

                            <p class="typography typography--paragraph"><b>System advantages:</b></p>
                            <ul class="base">
                                <li>Conducting meeting of different types – online, offline and mixed using single platform.</li>
                                <li>Collaborative work during preparation of agenda</li>
                                <li>Remote access to the agenda and creating notes before meetings.</li>
                                <li>Personal and group message exchange.</li>
                                <li>Automatic generation of meeting minutes.</li>
                                <li>Ability to manage system catalogs and module settings with the use of system interface.</li>
                                <li>A wide range of customization options for the system according to customer needs.</li>
                                <li>Backup server.</li>
                                <li>Several levels of protection which guarantee complete security of customer data.</li>
                            </ul>

                            <p class="typography typography--paragraph"></p>

                        </div>

                        <div class="one-solution__one" v-if="solutionId == 2">
                            <p class="typography typography--paragraph"><b>Brand new political campaign software for party organizations and political players which makes it easy to hold a campaign at all stages, communicate with electorate, logging all types of cooperation (meetings, requests, events, assistance and others), plan the work of the headquarters team in a centralized system during city elections as well as during national, collect ratings and opinions from voters for further use.</b></p>
                            <p class="typography typography--paragraph">Elect.pro gives the opportunity to make timely and effective decisions about elections company management on every separate station. Fast and convenient tools for entering information online help the team of agitators to work in a system using desktop or tablet computers “on the field” or at the office.</p>

                            <p class="typography typography--paragraph">The requests' management system allows you to quickly evaluate the electoral effect that fulfillment of every request gives, forecast, and control the costs of their implementation.</p>
                            <p class="typography typography--paragraph">Every step of the electoral campaign will be predicted, controlled, and effective.</p>

                            <h4 class="typography typography--h4">Capacity of the system<br>Tools for managing headquarters</h4>
                            <p class="typography typography--paragraph">Creation of projects and control of their effectiveness. Online task assignment and control of task completion. The use of a single calendar for headquarters.</p>

                            <h4 class="typography typography--h4">Managing of requests</h4>
                            <p class="typography typography--paragraph">Monitoring of all stages – from the appeal receiving to the assessment of the effectiveness of fulfillment.</p>

                            <h4 class="typography typography--h4">Integrated communications management</h4>
                            <p class="typography typography--paragraph">Own integrated call-center, the automatic formation of lists, mailing letters (notifications), and control of their effectiveness.</p>

                            <h4 class="typography typography--h4">Control of effectiveness</h4>
                            <p class="typography typography--paragraph">The use of the set of clear metrics for employee performance, control of communication with voters (touches), and creation of automated charts for result analysis.</p>

                            <h4 class="typography typography--h4">The account of resources</h4>
                            <p class="typography typography--paragraph">Creation of projects, accounting for the state of the warehouse, resource costs, location of tents, and holding the meetings.</p>

                            <h4 class="typography typography--h4">Training the agitation network</h4>
                            <p class="typography typography--paragraph">Training and certification of headquarters team members using the distance learning module.</p>

                        </div>

                        <div class="one-solution__one" v-if="solutionId == 3">
                            <p class="typography typography--paragraph"><b>E-learning web-based solution that covers all needs in training, testing and certification of employees, customers, partners, dealers and other contractors with modern tools using a smartphone, tablet and personal computer.</b></p>
                            <p class="typography typography--paragraph">A wide selection of built-in settings makes it possible to fully automate all internal business processes of the organization that are associated with training and personal certification. The possibility of visual customization of the system with respect to the corporate style of the organization.</p>

                            <h4 class="typography typography--h4">Intended for:</h4>

                            <ul class="base">
                                <li>State and commercial organizations;</li>
                                <li>Universities and schools;</li>
                                <li>Parties and dealership structures.</li>
                            </ul>

                            <h4 class="typography typography--h4">System features:</h4>
                            <p class="typography typography--paragraph"><b>Course Designer</b></p>
                            <p class="typography typography--paragraph">Built-in designer of training courses with the addition of any multimedia materials and the use of webinars for online lectures and conference calls.</p>

                            <p class="typography typography--paragraph"><b>Testing and Surveys</b></p>
                            <p class="typography typography--paragraph">Testing employees to verify acquired knowledge, well-timed certification and organization of T&E activity. Creation of arbitrary questionnaires and carrying out HR-tests from the automatic formation of results.</p>

                            <p class="typography typography--paragraph"><b>Statistics analysis</b></p>
                            <p class="typography typography--paragraph">Analysis of statistical information on the results of training, which allows you to prepare personal training and retraining plans for employees.</p>

                            <p class="typography typography--paragraph"><b>API</b></p>
                            <p class="typography typography--paragraph">API integration with company systems and web services. Scientia is built as a combination of web services on the concept of the RESTful API, so it can be integrated with any IT system.</p>

                            <p class="typography typography--paragraph"><b>Webinars</b></p>
                            <p class="typography typography--paragraph">A convenient tool for conducting training in the format of “live classes”: online video communication, an interactive whiteboard for common work and interaction with educational materials, the ability to give the floor to the listener, as well as general chat for communications.</p>

                            <p class="typography typography--paragraph"><b>Automatic generation of certificates</b></p>
                            <p class="typography typography--paragraph">Automatic generation of attainment certificates with the use of different types of templates in accordance with the corporate style.</p>
                        </div>

                        <div class="one-solution__one" v-if="solutionId == 4">
                            <p class="typography typography--paragraph"><b>A modern solution for the automation of all business processes that are connected with the organization and running events in conference rooms. The system allows you to optimize communication with potential renters and provides them with advanced functionality for interactive events and cooperation with visitors what will serve as a significant competitive advantage for your organization.</b></p>

                            <h4 class="typography typography--h4">Main capabilities:</h4>
                            
                            <p class="typography typography--paragraph"><b>For organization:</b></p>
                            <ul class="base">
                                <li>Optimization of organization operation and events management;</li>
                                <li>Providing online tools (via website) for rooms selection according to renter’s needs;</li>
                                <li>Room load distribution to prevent event crossing;</li>
                                <li>Additional income from the placement of promotional materials;</li>
                                <li>Improving the efficiency of premises use;</li>
                                <li>Statistics and reports about the room use;</li>
                                <li>Visual customization of the system in line with the corporate style of the hotel.</li>
                            </ul>

                            <p class="typography typography--paragraph"><b>For renters:</b></p>
                            <ul class="base">
                                <li>Convenient selection of rooms for the event using advanced filtering tools;</li>
                                <li>Using a ready-made promotional site with event data (landing page);</li>
                                <li>Telegram bot for effective communication with visitors;</li>
                                <li>Placing additional promotional materials (including ads) on information screens;</li>
                                <li>A wide range of ways to sell event tickets (web site, kiosk, etc)</li>
                            </ul>

                            <p class="typography typography--paragraph"><b>For guest:</b></p>
                            <ul class="base">
                                <li>Receiving an E-ticket or on-site ticket purchase;</li>
                                <li>Registration and interaction via Telegram bot;</li>
                                <li>Easy navigation through conference rooms and access to the necessary information about the event.</li>
                            </ul>
                        </div>

                        <div class="one-solution__one" v-if="solutionId == 5">
                            
                            <p class="typography typography--paragraph">Forum “Digitalization of politics” was devoted to the modern tools of the campaign.
                            – How to automate the work of the party headquarters? – How to effectively counter the falsification? – How to pick a team and increase voter engagement in their work? Answers to these questions sought political technologists, social scientists, public relations experts in the forum.</p>
                           
                            <p class="typography typography--paragraph">
                                Experts discussed the modern tools of the campaign and concluded that the upcoming election campaign is difficult to imagine without digital technology. Digital opens new possibilities and simplifies organizational work at times.
                            </p>
                            <p class="typography typography--paragraph">In particular, digital system of election campaign Elect.Pro allows managing the work of staff and campaign network, monitor their performance, keep track of resources and distance learning agitators.</p>
                            <p class="typography typography--paragraph">The forum was attended by deputies of Ukraine Maxim Savrasov, head of ICT KCSA Yuri Nazarov, President Bohush Communications Denis Bogush, SMM-specialist Michael Crow, political scientist NGO “Ukrainian center of social development” Igor Reyterovych, PR-manager “YevroOptymisty” Anna Simonyan and sociologist, founder ACTIVE GROUP Andrei Yeremenko.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* Waves */
import "@/plugins/jquery.ripples.js"

import Navigation from "@/components/Navigation";
import Preloader from "@/components/Preloader"

export default {
    name: "Service",
    components: {
        Navigation,
        Preloader
    },

    data() {
        return {
            title: "",
            preloader: true,
            stepHidden: true,
            remountKey: 0,
        };
    },

    computed: {
        solutionId() {
            if (!this.$route.params?.id) {
                if (this.$route.params.name == 'patentem') return 1
                if (this.$route.params.name == 'elect-pro') return 2
                if (this.$route.params.name == 'scientia') return 3
                if (this.$route.params.name == 'bus-station') return 4
                if (this.$route.params.name == 'digitalization-of-politics') return 5
            }
            return this.$route.params.id
        },
    },

    watch: {
        solutionId(v) {
            this.getData(v);
        },
    },

    mounted() {
        this.getData(this.solutionId);
        setTimeout(() => {
            this.preloader = false
        }, 700);

        /* if ($('.one-solution__ripples canvas').length == 0) {
            $('.one-solution__ripples').ripples({
                perturbance: 0.04
            });
        }  */
    },

    methods: {
        getData(n) {
            if (typeof n == 'string') {
                n = +n
            }
            switch (n) {
                case 1:
                    this.title = this.$t('sections.solutions.Solution1')
                    break;
                case 2:
                    this.title = this.$t('sections.solutions.Solution2')
                    break;
                case 3:
                    this.title = this.$t('sections.solutions.Solution3')
                    break;
                case 4:
                    this.title = this.$t('sections.solutions.Solution4')
                    break;
                case 5:
                    this.title = this.$t('sections.solutions.Solution5')
                    break;
            }

            window.document.title = this.title + ' | STERKONIX'
        },

        changeLocale() {
            setTimeout(() => {
                this.getData(this.$route.params.id)
            }, 100);
        }
    },

   
};
</script>

<style lang="scss" scoped>

.one-solution {

    .section__content {
        min-height: 100vh;

        @media screen and (max-width: $lg) {
            overflow-y: auto;
            overflow-x: hidden;
            background-color: $color_black;
        }
    }

    .section__title {
        position: relative;
        font-size: 55px;

        @media screen and (max-width: $lg) {
            span {
                color: white;
            }
        }

        @media screen and (max-width: $lg) {
            padding-top: 140px;
            position: absolute;
        }
    }

    &__bookmarks {
        background-color: #3438397d;
        padding: 11px 15px;
        color: white;
        border-radius: 4px;
        display: flex;
        align-items: center;
        z-index: 222;
        position: absolute;
        width: fit-content;
        font-size: 12px;

        @media screen and (max-width: $lg) {
            top: 90px;
        }

        img {
            margin-right: 10px;
            height: 20px;
        }
    }

    .section__right {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;

        @media screen and (max-width: $lg) {
            width: 100vw;
            position: absolute;
            margin-top: 500px;
        }
    }

    .section__left {
        @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 900px) {
            padding-top: 70px;
        }
    }

    &__content {
        margin: 140px 12vw 40px 4vw;
        margin-right: 0;
        padding-right: 4vw;
        overflow: auto;

        @media screen and (max-width: $lg) {
            margin: 40px 0 40px 20px;
            overflow: initial;
            padding-bottom: 40px;
        }
    }

    &__ripples {
        height: 100vh;
        width: 50vw;
        position: fixed;
        left: 0;
        top: 0;
        background-position: center;
        background-size: 100%;
        transition: .3s;
        background-color: $color_black;

        @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 900px) {
            height: 100vh;
        }

        @media screen and (max-width: $lg) {
            height: 500px;
            width: 100vw;
            position: absolute;
        }

        &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: #3f41647a;
            transition: .2s;
            left: 0;
            top: 0;
        }
    }

    &__one {
        h4:not(:first-child) {
            margin-top: 40px;
            color: $color_red;
        }
    }
}
</style>
