<template>
    <div class="fast-customers-wrapper">
        <div class="fast-customers-button" v-if="!modalOpen" @click="modalOpen = true">
              <img src="@/assets/img/fast_customers.png" alt="png">
          </div>
        
        <transition name="fade">
            <div class="fast-customers-modal" v-if="modalOpen">
                <div class="fast-customers-modal__content">
                    <h3 class="typography typography--h3 fast-customers-modal__title">Our customers</h3>
                    <div class="fast-customers-modal__list">
                        <div class="fast-customers-modal__item" v-for="(c, index) in casesLength" :key="index" @click="itemClick(c)">
                            <img v-if="c == 3" :src="require(`@/assets/img/customers/3_1.png`)" alt="png">
                            <img v-else :src="require(`@/assets/img/customers/${index + 1}.png`)" alt="png">
                        </div>
                    </div>
                </div>
                <div class="fast-customers-modal__close" @click="modalOpen = false">
                    <img :src="require(`@/assets/img/close.png`)" alt="png">
                </div>
            </div>
        </transition>
    </div>
  
</template>

<script>
export default {

    data() {
        return {
            modalOpen: false,
            casesLength: 9
        }
    },

    methods: {
        itemClick(i) {
            this.modalOpen = false
            this.$emit('fallback');

            switch (i) {
                case 1:
                    this.$router.push({ name: 'Customer', params: { id: i, name: 'es', item: 1, serviceName: 'es_platform' } }).catch(() => {})
                break;
                case 3:
                    this.$router.push({ name: 'Customer', params: { id: i, name: 'overprint', item: 1, serviceName: 'online_calculator' } }).catch(() => {})
                break;
                case 4:
                    this.$router.push({ name: 'Customer', params: { id: i, name: 'kyivcitycouncil', item: 1, serviceName: 'kyiv_infoscreens' } }).catch(() => {})
                break;
                case 5:
                    this.$router.push({ name: 'Customer', params: { id: i, name: 'kpt', item: 1, serviceName: 'traffic_system' } }).catch(() => {})
                break;
                case 6:
                    this.$router.push({ name: 'Customer', params: { id: i, name: 'as_vydubichi', item: 1, serviceName: 'bus_ticket_system' } }).catch(() => {})
                break;
                case 7:
                    this.$router.push({ name: 'Customer', params: { id: i, name: 'uaf', item: 1, serviceName: 'reports_system' } }).catch(() => {})
                break;
                case 8:
                    this.$router.push({ name: 'Customer', params: { id: i, name: 'upl', item: 1, serviceName: 'website' } }).catch(() => {})
                break;
                case 9:
                    this.$router.push({ name: 'Customer', params: { id: i, name: 'president-hotel', item: 1, serviceName: 'information_screens' } }).catch(() => {})
                break;
            }
        }
    }

}
</script>

<style lang="scss" scoped>
    .fast-customers-wrapper {
        width: 100vw;
        position: absolute;
    }

    .fast-customers-button {
        height: 80px;
        width: 80px;
        background: $color_red;
        z-index: 100;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 10000;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: 40px;

        @media screen and (max-width: $xxl) {
            height: 60px;
            width: 60px;

            img {
                height: 20px;
            }
        }
    }

    .fast-customers-modal {
        width: 100%;
        height: 100%;
        background-color: #111111ed;
        z-index: 10000;
        position: fixed;

        &__content {
            width: 1100px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 10vh;
        }

        &__title {
            color: $color_red;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 50px;
        }

        &__item {
            padding: 20px;
            height: 14vh;
            width: calc(30% - 30px);
            background-color: #575b5f8c;
            border-radius: 16px;
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: .2s;

            &:nth-child(2) {
                cursor: initial;
            }

            img {
                max-width: 150px;
                max-height: 60px;
                filter: grayscale(0.8) brightness(5) grayscale(1);
            }

            &:hover {
                background-color: #ff193db8;
                
                /* img {
                    filter: none;
                } */
            }
        }

        &__close {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: #ff193d;
            border-radius: 50%;
            height: 50px;
            width: 50px;
            transition: .2s;
            right: 40px;
            position: absolute;
            top: 40px;

            img {
                filter: invert(1);
                max-width: 100%;
                height: 20px;
            }
        }
    }
</style>