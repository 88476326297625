export default {

    /* navigation */
    navigation: {
        'main': 'головна',
        'about 3k' : 'про нас',
        'solutions' : 'проекти',
        'vacancies' : 'вакансії',
        'contacts' : 'контакти',
        'presentations' : 'презентації',
        'services' : 'сервiси',

        'Business Analysis & Consulting' : 'Business Analysis & Consulting',
        'Digital Transformation' : 'Digital Transformation',
        'Solutions Integration' : 'Custom Software Development',
        'Custom Software Development' : 'Custom Software Development',
        'Mobile Solutions' : 'Mobile Solutions',
        'Software Design, Architecture, and Prototyping' : 'Software Design, Architecture, and Prototyping',

        'Presentation' : 'Презентація',

        'videos' : 'відео',

        'back' : 'Назад',
    },


    sections: {
        main: {
            'Are experts in': 'Ми эксперти з',
            'Consulting' : 'Консалтингу',
            'Business process analysis' : 'Аналізу бізнес-процесів',
            'Audit' : 'Аудиту',
            'Development' : 'Розробки',
            'Integrations' : 'Інтеграції',
            'Mobile applications' : 'Мобільних додаткiв',
        },

        howWorking: {
            'How we' : 'Як ми',
            'are working' : 'працюємо',
            'STEP' : 'ШАГ',
            'Design – Prototyping – Technical Requirements' : 'слухати — записувати — аналізувати',
            'Backlog – Estimating – Prioritization' : 'дизайн — модель — документ',
            'Developing – Testing – Deploying' : 'дизайн — отримати відгук — погодитися',
        },

        solutions: {
            'Solution1' : 'Проект1',
            'Solution2' : 'Проект2',
            'Solution3' : 'Проект3',

            'About company caption' : 'Про компанiю',
            'Company description' : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae doloribus quod eveniet ipsam quidem obcaecati.[read more link]',
            'About solution1 caption' : 'Про проект1',
            'Solution1 description' : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae doloribus quod eveniet ipsam quidem obcaecati.[read more link]',
            'Solution1 link' : '[more link]',
            'Solution1 description full' : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae doloribus quod eveniet ipsam quidem obcaecati. Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae doloribus quod eveniet ipsam quidem obcaecati.',
            'About solution2 caption' : 'Про проект2',
            'Solution2 description' : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae doloribus quod eveniet ipsam quidem obcaecati.[read more link]',
            'Solution2 link' : '[more link]',
            'Solution2 description full' : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae doloribus quod eveniet ipsam quidem obcaecati. Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae doloribus quod eveniet ipsam quidem obcaecati.',
            'About solution3 caption' : 'Про проект3',
            'Solution3 description' : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae doloribus quod eveniet ipsam quidem obcaecati.[read more link]',
            'Solution3 link' : '[more link]',
            'Solution3 description full' : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae doloribus quod eveniet ipsam quidem obcaecati. Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae doloribus quod eveniet ipsam quidem obcaecati.',

        },

        services: {
            'Our' : 'Нашi',
            'services' : 'сервiси',
            '1Description' : 'evaluating your technology choices, optimising your processes and frame. Offering solutions that can boost your business, making it more efficient, streamlined and profitable.',
            '2Description' : 'building intelligent digital solutions to automate manual and inefficient business processes.',
            '3Description' : 'hardware and software integration to build the complex ecosystem, making it easy to scale, adapt and adjust as business requirements change.',
            '4Description' : 'creating new digital products (mobile & web) at the intersection of market opportunity, customer experience and emerging technology.',
            '5Description' : 'building intuitive and reliable applications for iOS and Android, Chat Bots development for ​​ready access to information and channel integration with Telegram, Facebook, Whatsapp, Viber.',
            '6Description' : 'designing intuitive digital experiences that are optimised across web, tablet, and mobile devices using the best UI/UX practices. ',
            'All services' : 'Всi сервiси'
        },

        vacancies: {
            'Our' : 'Нашi',
            'vacancies' : 'вакансії',
            'list' : [
                {
                    'title' : 'Middle PHP Developer (Laravel)',
                    'resp' : [
                        'Expertise in object-oriented programming in PHP',
                        'Laravel',
                        'PHP, MySQL, JavaScript, NodeJS, Docker, jQuery'
                    ]
                },
                {
                    'title' : 'Middle PHP Developer (YII2)',
                    'resp' : [
                        'Expertise in object-oriented programming in PHP',
                        'YII2',
                        'PHP, MySQL, JavaScript, NodeJS, Docker, jQuery'
                    ]
                },
                {
                    'title' : 'Front-end developer',
                    'resp' : [
                        'Vue.js',
                        'Converting wireframes, prototypes, sketches, and high-fidelity mockups into usable and responsive applications',
                        'Writing semantic, accessibility minded, and scalable development'
                    ]
                },
                {
                    'title' : 'Middle QA Engineer (Manual)',
                    'resp' : [
                        'Develop acceptance test criteria for our solutions and products',
                        'Create manual tests to ensure we constantly deliver quality products',
                        'Develop and monitor performance metrics'
                    ]
                }
            ],
            'Respond' : 'Вiдгукнутися',
            'Hide' : 'Згорнути',
        },

        about: {
            'About' : 'Про',
            'title' : 'Lorem ipsum dolor sit amet',
            'p' : [
                `Product and Software Development company. Our team is inspired to provide full cycle development services - web application architecture, UX/UI design, development, QA, deployment.`,
                `To every project, we bring a combination of domain expertise and exceptional engineering talent of our prof developers.`,
                `Sterkonix applies a full agile methodology, not only for software development, but in building company culture too. Our teams are not afraid of taking on new challenges and encouraged in non-standard requests.`
            ],
            'Contact' : 'Зв\'язатися'
        },

        contacts: {
            'Our' : 'Нашi',
            'contacts' : 'контакти',
            'Contact us' : 'Зв\'язатися з нами',
            'form' : {
                'Name' : 'Iм\'я',
                'Name error' : 'Будь ласка введiть корректне iм\'я',
                'E-mail' : 'E-mail',
                'E-mail error' : 'Будь ласка введiть корректний email',
                'Phone' : 'Телефон',
                'Phone error' : 'Будь ласка введiть корректний телефон',
                'Type your message' : 'Введiть текст',
                'Type your message error' : 'Заповнiть це поле'
            },
            'Contact' : 'Зв\'язатися'
        },

        customers: {
            'Our' : 'Нашi',
            'customers' : 'клієнти',
        },

        footer: {
            'Services:' : 'Services:',
            'Solutions:' : 'Solutions:',
        }
    },

     /* inner pages */
    pages: {
        service: {
            'Consulting' : 'Консалтинг',
            'Business analysis' : 'Аналіз бізнес-процесів',
            'Audit' : 'Аудит',
            'Development' : 'Розробка',
            'Integrations' : 'Інтеграції',
            'Mobile apps' : 'Мобільні додатки',
        }
    }

}