import Vue from 'vue'
import LangRouter from 'vue-lang-router';
import translations from '@/lang/translations';

Vue.use(LangRouter, {
  defaultLanguage: 'en',
  translations
})

const router = new LangRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
      {
        path: '/',
        name: 'Home',
        meta: {
          title: 'Home page | Sterkonix'
        },
        component: () => import('@/views/Home'),
      },
      {
        path: '/service/:name',
        name: 'Service',
        component: () => import('@/views/Service'),
      },
      {
        path: '/solution/:name',
        name: 'Solution',
        component: () => import('@/views/Solution'),
      },
      {
        path: '/customer/:name/:serviceName',
        name: 'Customer',
        component: () => import('@/views/Customer'),
      },
      {
        path: '/customer/:name/',
        name: 'CustomerMobile',
        component: () => import('@/views/CustomerMobile'),
      },
  ]
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (from.path !== to.path) {
    if ($('.solutions__ripples').length) {
      $('.solutions__ripples').ripples('destroy');
    }
  }
  
  next()
})

export default router
