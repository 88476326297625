<template>
    <div class="section contacts">
        <div class="section__content">
            <div class="section__left">
                <h2 class="typography typography--h1 section__title">
                    <span class="white">{{ $t('sections.contacts.Our') }} </span>
                    <span class="red">{{ $t('sections.contacts.contacts') }}</span>
                </h2>
                <div class="contacts__figure"></div>
                <video ref="contacts__video" autoplay muted loop playsinline class="section__leftVideo">
                  <source src="@/assets/video/glitch1.mp4" type="video/mp4">
                </video>

                <div class="contacts__images">
                    <img src="@/assets/img/contacts_info1.png" alt="png">
                    <img src="@/assets/img/contacts_info2.png" alt="png">
                </div>

                <div class="contacts__socials">
                    <!--<a href="https://www.facebook.com/3k.group.software"><div class="contacts__socialButton"><img src="@/assets/img/fb.png" alt="png"></div></a>-->
                    <a href="https://www.linkedin.com/company/65389857"><div class="contacts__socialButton"><img src="@/assets/img/in.png" alt="png"></div></a>
                </div>
            </div>
            <div class="section__right">
                <p class="contacts__text typography typography--paragraph">If you have any questions that were not answered above, please feel free to contact us. We will provide you with detailed information about our products and services. Our managers will arrange product demonstrations and send all needed materials due to your request.</p>
                <!-- <h4 v-if="!isMobile" class="typography typography--h4 contacts__title">{{ $t('sections.contacts.Contact us') }}</h4>
                <form ref="contactsForm" class="form contacts__form">

                    <input type="hidden" name="project_name" value="3k Group">
                    <input type="hidden" name="admin_email" value="a.zaiats@3k.group">
                    <input type="hidden" name="form_subject" value="Contact us">
                    
                    <input class="form__input" name="username" v-model="username" :placeholder="$t('sections.contacts.form.Name')">
                    <span class="error">{{ errors.username }}</span>
                    <input class="form__input" name="email" v-model="email" :placeholder="$t('sections.contacts.form.E-mail')">
                    <span class="error">{{ errors.email }}</span>
                    <vue-tel-input class="form__input phone" @input="changePhone" v-model="phone" @validate="validate" :inputOptions="inputOptions"></vue-tel-input>
                    <span class="error">{{ errors.phone }}</span>
                    <textarea class="form__textarea message" name="message"  v-model="message" rows="5" :placeholder="$t('sections.contacts.form.Type your message')"></textarea>
                    <span class="error">{{ errors.message }}</span>

                    <vue-recaptcha
                        size="invisible"
                        ref="recaptcha"
                        :sitekey="'6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'"
                        :loadRecaptchaScript="true"
                        @verify="submitForm"
                        @expired="onCaptchaExpired"
                    />
                    
                    <ButtonComponent @click="submitForm" :type="'button'" class="contacts__button" :caption="$t('sections.contacts.Contact')"/>
                </form> -->

            </div>
        </div>
    </div>
</template>

<script>
/* import VueRecaptcha from 'vue-recaptcha' */

export default {

    components: {
        /* VueRecaptcha */
    },

    data() {
        return {
           /*  username: '',
            email: '',
            phone: '',
            message: '',
            errors: {
                username: '',
                email: '',
                phone: '',
                message: '',
            },
            inputOptions: {
                enabledCountryCode: false,
                mode: "international",
                placeholder: this.$t('sections.contacts.form.Phone')
            },
            isPhoneDirty: false, */
        }
    },

    methods: {
       /*  submitForm() {
            if (!this.phone.length || this.errors.phone) {
                this.errors.phone = this.$t('sections.contacts.form.Phone error')
                return false
            }
            this.clearErrors()

            let regExpName = /^[a-z ,.'-]+$/i ;
            let regExpEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!regExpName.test(this.username)) {
                this.errors.username = this.$t('sections.contacts.form.Name error')
                return false
            }
            if (!regExpEmail.test(this.email)) {
                this.errors.email = this.$t('sections.contacts.form.E-mail error')
                return false
            }

            if (!this.message.length) {
                this.errors.message = this.$t('sections.contacts.form.Type your message error')
                return false
            }

            let formData = new FormData(this.$refs.contactsForm)
            formData.append('phone', this.phone)

            let ds = this.serialize(formData);

            this.axios({
              method: 'post', //you can set what request you want to be
              url: '../../../static/mail.php',
              data: ds,
            }).then(() => {
                this.username = '';
                this.email = '';
                this.phone = '';
                this.message = '';
                this.errors = {
                    username: '',
                    email: '',
                    phone: '',
                    message: '',
                };
            }).catch((e) => {
                console.log('error');
            })

            this.onCaptchaExpired();
        },

        serialize(data) {
            let obj = {};
            for (let [key, value] of data) {
                if (obj[key] !== undefined) {
                    if (!Array.isArray(obj[key])) {
                        obj[key] = [obj[key]];
                    }
                    obj[key].push(value);
                } else {
                    obj[key] = value;
                }
            }
            return obj;
        },

        clearErrors() {
            this.errors = {
                username: '',
                email: '',
                phone: '',
                message: '',
            }
        },

        onCaptchaExpired() {
            this.$refs.recaptcha.reset()
        },

        changePhone(v) {
            if (this.isPhoneDirty == false && v.length) {
                this.isPhoneDirty = !this.isPhoneDirty
                this.errors.phone = this.$t('sections.contacts.form.Phone error')
            }
        },

        validate(phoneObject) {
            if (phoneObject.valid === true) {
                this.phone = phoneObject.number;
                this.errors.phone = ''
            } else if (phoneObject.valid === false) {
                this.phone = '';
                this.errors.phone = this.$t('sections.contacts.form.Phone error')
            }
        }, */
    }
};
</script>

<style lang="scss" scoped>
.contacts {

    .section__content {
        @media screen and (max-width: $lg) {
            flex-direction: column;
            background-color: $color_black;
        }
    }

    .section__right {
        display: flex;
        flex-direction: column;
        padding-bottom: 100px;

        @media screen and (max-width: $xl) {
            padding-top: 90px;
            justify-content: flex-start;
        }

        @media screen and (max-width: $lg) {
            position: relative;
            width: 100vw;
            padding: 40px 20px;
            background-color: transparent;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            padding-top: 60px !important;
        }

        .message {
            margin-bottom: 15px;
        }

        .phone {
            padding: 0;
            height: 46px;
            margin-bottom: 15px;

            ::v-deep .vti__input {
                background-color: $color_light;
                font-family: $ff-primary;
                font-size: 18px;
                font-weight: 300;
                color: black;

                @media screen and (max-width: $lg) {
                    background-color: transparent;
                    color: white;
                    
                    &::placeholder {
                        color: #bfc4c5;
                    }
                }
            }

            &:focus-within {
                border-color: transparent;
                box-shadow: none;
            }
        }
    }

    @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 900px) {
        .section__title {
            width: 400px;
            max-width: 100%;
        }
    }

    @media screen and (max-width: 1600px) {
        .section__title {
            font-size: 90px;
        }
    }

    @media screen and (max-height: 759px) and (min-width: $xl) {
        .section__title {
            position: relative;
            font-size: 85px;
        }

        .section__right {
            padding-top: 150px;
            padding-bottom: 0;
        }
    } 

    @media screen and (max-width: $xl) {
        .section__title {
            font-size: 69px;
        }
    }
    
    &__text {
        margin-top: calc(140px + 195px);

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            margin-top: calc(140px + 20px);
        }

        @media screen and (max-width: $lg) {
            margin-top: 0;
            margin-bottom: 20px;
        }
    }

    &__form {
        input {
            margin-bottom: 15px;
        }
    }

    &__title {
        margin-bottom: 40px;
    }

    &__figure {
        background-position: center;
        background-size: contain;
        height: 65vh;
        width: 65vw;
        left: calc(0vw - 24vw);
        transform: rotate(90deg);
        bottom: -17vh;
        z-index: 2;
        position: absolute;
        background-repeat: no-repeat;

        background-image: url("~@/assets/img/contacts.png");
        opacity: .6;

        @media screen and (max-width: $xl) {
            background-image: url("~@/assets/img/contacts.png");
            opacity: .6;
            height: 400px;
            width: 400px;
            left: 4vw;
            transform: rotate(90deg);
            top: 38vh;
        }

        @media screen and (max-width: $lg) {
            height: 600px;
            width: 600px;
            left: -22vw;
            opacity: .3;
        }
    }

    &__images {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 10;
        padding-right: 30px;
        padding-top: 200px;

        @media screen and (max-width: $lg) {
            height: auto;
            margin-top: 40px;
            padding-top: 0;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            padding-top: 30px;
        }

        img {
            width: fit-content;
            max-width: 100%;

            @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
                max-width: 55%;
            }

            @media screen and (max-height: 800px) and (min-height: 739px) {
                max-width: 60%;
            }

            @media screen and (min-width: $lg) and (max-width: $xl) {
                max-width: 60%;
            }

            @media screen and (max-width: $lg) {
                max-width: 40vw;
            }

            &:first-child {
                margin-top: auto;
                margin-bottom: 60px;

                @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
                    margin-bottom: 40px;
                }

                @media screen and (max-width: $sm) {
                    margin-bottom: 20px;
                }
            }
        }
    }

    &__socials {
        margin-top: 10vh;
        display: flex;
        align-items: center;
        padding-bottom: 40px;
        justify-content: flex-start;

        @media screen and (max-width: $lg) {
            margin-top: 40px;
        }
    }

    &__socialButton {
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color_red;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        z-index: 10;
        outline: none;

        &:hover {
            box-shadow: 0 8px 12px -6px rgba(255, 25, 61, 0.4);
        }

        &:first-child {
            margin-right: 10px;
        }

        img {
            height: 24px;
        }
    }

    &__button {
        margin-top: 60px;

        @media screen and (max-height: $md) {
            margin-top: 0;
        }
    }
}
</style>
