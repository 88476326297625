<template>
    <div class="section how-working">
        <div class="section__content">
            <div class="section__left">
                <h2 class="typography typography--h2 section__title">
                    <span class="white">{{ $t('sections.howWorking.How we') }} </span>
                    <span class="red">{{ $t('sections.howWorking.are working') }}</span>
                </h2>
                <div class="how-working__figure"></div>
                <video ref="howWorking__video" autoplay muted loop playsinline class="section__leftVideo">
                  <source src="@/assets/video/glitch1.mp4" type="video/mp4">
                </video>

                <span class="how-working__stepLight">{{ $t('sections.howWorking.STEP') }}</span>
                <span class="how-working__stepBold">0{{ imgId }}</span>

                <div :style="'background-image: url('+ mainSlideImg + ')'" class="how-working__mainCircle" :class="{'how-working__mainCircle--hidden' : !stepHidden}"></div>
                
                <div @click="goNext" :style="'background-image: url(' + require('@/assets/img/square_circle.svg') + ')'" class="how-working__squareCircle" :class="{'how-working__mainCircle--new' : stepNew}"></div>
                <div @click="goNext" :style="'opacity:0; background-image: url(' + mainSlideImg + ')'" class="how-working__squareCircle" :class="{'how-working__squareCircle--new' : !stepNew}"></div>

            </div>
            <div class="section__right">
                <div class="how-working__contentText">
                    <h4 class="typography typography--h4">
                        <span class="typed-text">{{ typeValue }}</span>
                        <span class="cursor" :class="{'typing': typeStatus}">&nbsp;</span>
                    </h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            imgId: 1,

            typeValue: '',
            typeStatus: false,
            typeArray: [],
            typingSpeed: 50,
            erasingSpeed: 0,
            newTextDelay: 2000,
            typeArrayIndex: 0,
            charIndex: 0,

            stepHidden: true,
            stepNew: true
        }
    },
    
    computed: {
        mainSlideImg() {
            try {
                return require(`@/assets/img/how/${this.imgId}.png`)
            } catch (error) {
                return require(`@/assets/img/how/1.png`)
            }
        },
    },

    created() {
        //setTimeout(this.typeText, this.newTextDelay + 200);
            this.typeArray = [
                this.$t('sections.howWorking.Design – Prototyping – Technical Requirements'),
                this.$t('sections.howWorking.Backlog – Estimating – Prioritization'), 
                this.$t('sections.howWorking.Developing – Testing – Deploying'), 
            ];

        this.typeText()
    },

    methods: {
        typeText() {
            if(this.charIndex < this.typeArray[this.typeArrayIndex].length) {
                if(!this.typeStatus)
                this.typeStatus = true;
                this.typeValue += this.typeArray[this.typeArrayIndex].charAt(this.charIndex);
                this.charIndex += 1;
                setTimeout(this.typeText, this.typingSpeed);
            }
            else {
                this.typeStatus = false;
                
            }
        },
        eraseText() {
            if(this.charIndex > 0) {
                if(!this.typeStatus)
                    this.typeStatus = true;
                this.typeValue = this.typeArray[this.typeArrayIndex].substring(0, this.charIndex - 1);
                this.charIndex -= 1;
                setTimeout(this.eraseText, this.erasingSpeed);
            }
            else {
                this.typeStatus = false;
                this.typeArrayIndex += 1;
                if(this.typeArrayIndex >= this.typeArray.length)
                    this.typeArrayIndex = 0;
                setTimeout(this.typeText, this.typingSpeed + 1000);
            }
        },

        goNext() {
            this.changeImage()
            this.eraseText()
        },

        changeImage() {
            this.imgId == 3 ? this.imgId = 1 : this.imgId += 1;
            this.stepHidden = false;
            this.stepNew = false;

            setTimeout(() => {
                this.stepHidden = true;
                this.stepNew = true;
            }, 990);

        }
    }

};
</script>

<style lang="scss" scoped>
.how-working {

    .section__content {
        @media screen and (max-height: 759px) and (min-width: $xl) {
            min-height: 900px;
        } 

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            min-height: auto;
        } 
    }

    .section__right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 100px;
        
        @media screen and (max-width: $lg) {
            width: 100vh;
            position: absolute;
            bottom: 0;
            background-color: transparent;
            padding-bottom: 50px;
        }
    }

    .section__left {
        @media screen and (max-width: $xl) {
            padding-bottom: 650px;
        }

        @media screen and (max-width: $sm) {
            padding-bottom: 510px;
        }

        @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 900px) {
            padding-top: 15vh;
        }
    }

    .section__title {
        @media screen and (max-width: $xl) {
            position: absolute;
        }

        @media screen and (max-width: $lg) {
            position: relative;
        }
    }

    &__form {
        input {
            margin-bottom: 20px;
        }
    }

    &__figure {
        background-position: center;
        background-size: cover;
        height: 700px;
        width: 700px;
        left: -10vw;
        transform: rotate(40deg);
        top: 60vh;
        z-index: 1;
        position: absolute;
        background-repeat: no-repeat;

        background-image: url("~@/assets/img/contacts.png");
        opacity: .6;

        @media screen and (max-width: $xxl) {
            height: 500px;
            width: 500px;
            left: -18vw;
            transform: rotate(40deg);
            top: 72vh;
        }

        @media screen and (max-width: $lg) {
            opacity: .3;
        }

    }

    &__squareCircle {
        background-position: center;
        background-size: cover;
        height: 380px;
        width: 380px;
        left: calc(50vw - 190px);
        z-index: 1;
        bottom: 140px;
        position: absolute;
        background-repeat: no-repeat;

        &::after {
            left: calc(50% - 30px);
            top: 50%;
            transform: translate(-50%, -50%);
            background-image: url('~@/assets/img/finger.png');
            height: 60px;
            background-repeat: no-repeat;
            background-size: contain;
            width: 60px;
            content: '';
            position: absolute;
            transition: .3s;
        }

        &--new {
            transition: 1s;
            opacity: 1 !important;
            left: calc(50vw - 700px);

            &::after {
                opacity: 0;
            }
        }

        @media screen and (min-width: $fullhd + 1px) {
            bottom: 25vh;
        }

        @media screen and (max-width: 1500px) {
            &--new {
                left: calc(100vw - 90px);
            }
        }

        @media screen and (max-width: $lg) {
            left: calc(100vw - 90px);
            height: 180px;
            width: 180px;
            bottom: 240px;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            left: calc(50vw - 110px);
            height: 220px;
            width: 220px;
            bottom: 17vh;

            &::after {
                left: calc(50% - 20px);
                height: 40px;
                width: 40px;
            }

            &--new {
                left: 12vw;
            }
        } 
        @media screen and (max-width: $sm) {
            &::after {
                left: calc(50% - 20px);
                height: 40px;
                width: 40px;
            }
            
            &--new {
                left: 20px;
                height: 250px;
                width: 250px;
                bottom: 206px;
            }
        }
    }

    &__mainCircle {
        background-position: center;
        background-size: cover;
        height: 380px;
        width: 380px;
        left: calc(50vw - 700px);
        bottom: 140px;
        z-index: 2;
        position: absolute;
        background-repeat: no-repeat;
        transition: none;

        
        @media screen and (min-width: $fullhd + 1px) {
            bottom: 25vh;
        }

        @media screen and (max-width: 1500px) {
            left: 10vw
        }

        @media screen and (max-width: $sm) {
            height: 250px;
            width: 250px;
            left: 20px;
            bottom: 206px;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            height: 220px;
            width: 220px;
            left: 12vw;
            bottom: 17vh;
        }

        &--hidden {
            left: -51vw;
            transition: 1s;

            @media screen and (max-width: $lg) {
                left: -101vw
            }
        }


    }

    &__stepLight {
        font-size: 46px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 4px;
        color: white;
        left: calc(50vw - 448px);
        bottom: calc(140px * 3 + 80px);
        position: absolute;
        z-index: 4;

        @media screen and (min-width: $fullhd + 1px) {
            bottom: 55vh;
        }

        @media screen and (max-width: $xxl) {
            font-size: 30px;
            left: 30vw;
        }

        @media screen and (max-width: $sm) {
            display: none;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            bottom: 55vh;
            left: 22vw;
        }
    }

    &__stepBold {
        font-size: 72px;
        font-weight: 900;
        font-family: "Apple";
        letter-spacing: 6.26px;
        color: white;
        left: calc(50vw - 430px);
        bottom: calc(140px * 3);
        position: absolute;
        z-index: 4;

        @media screen and (min-width: $fullhd + 1px) {
            bottom: 48vh;
        }

        @media screen and (max-width: $xxl) {
            font-size: 50px;
            left: 30vw;
            bottom: calc(140px * 3 + 30px);
        }

        @media screen and (max-width: $sm) {
            display: none;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            bottom: 47vh;
            left: 22vw;
        }
    }

    &__contentText {
        margin-right: 0;
        max-width: 100%;
        bottom: 312px;
        position: absolute;
        max-width: calc(50vw - 10vw);

        @media screen and (min-width: $fullhd + 1px) {
            bottom: 39vh;
        }

        @media screen and (max-width: $lg) {
            bottom: 0;
            padding: 0 20px;
            left: 50vw;
            transform: translate(-50%, 0);
            width: calc(100vw - 40px);
        }

        @media screen and (max-width: $sm) {
            bottom: 50px;
            max-width: 100%;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            bottom: 32vh;
        }

        h4 {
            font-size: 26px;
            letter-spacing: 2.78px;
            line-height: 40px;
            margin-top: 25px;

            @media screen and (max-width: $xxl) {
                font-size: 20px;
            }

            @media screen and (max-width: $lg) {
                margin-top: 0;
                text-align: center;
            }

            span.typed-text {
                text-transform: uppercase;
                color: $color_black;

                @media screen and (max-width: $lg) {
                    color: white;
                }
            }
            span.cursor {
                display: inline-block;
                width: 4px;
                background-color: #dce3e4;
                animation: cursorBlink 1s infinite;
            }
            span.cursor.typing {
                animation: none;
            }
        }

        @keyframes cursorBlink {
            49% { background-color: #dce3e4; }
            50% { background-color: transparent; }
            99% { background-color: transparent; }
        }
    }

}
</style>
