<template>
    <div class="section customers">
        <div class="section__content">
            <div class="section__left">
                <h2 class="typography typography--h2 section__title">
                    <span class="white">{{ $t('sections.customers.Our') }} </span>
                    <span class="red">{{ $t('sections.customers.customers') }}</span>
                </h2>
                <video ref="customers__video" v-if="!isMobile" autoplay muted loop playsinline class="section__leftVideo">
                  <source src="@/assets/video/glitch1.mp4" type="video/mp4">
                </video>
            </div>
            <div class="section__right"></div>

            <div class="customers__list">
                <VueSlickCarousel class="customers-slider" v-bind="settings">
                    <div class="customers-slider__itemWrapper">
                        <div class="customers-slider__row">
                            <div class="customers-slider__item" @click="goToCustomerPage(1, 'es', 'es_platform')">
                                <img class="customers-slider__itemImg" :src="require(`@/assets/img/customers/1.png`)" alt="png">
                            </div>
                             <div class="customers-slider__item" :style="'cursor: initial'">
                                <img class="customers-slider__itemImg" :src="require(`@/assets/img/customers/2.png`)" alt="png">
                            </div>
                        </div>
                         <div class="customers-slider__item" @click="goToCustomerPage(3, 'overprint', 'online_calculator')" @mouseover="customersImg[3-1].hoovered = 3" @mouseleave="customersImg[3-1].hoovered = null">
                            <img class="customers-slider__itemImg" :src="customersImg[3-1].hoovered === 3 ? customersImg[3-1].color : customersImg[3-1].white" alt="png">
                        </div>
                    </div>
                    <div class="customers-slider__itemWrapper">
                        <div class="customers-slider__row">
                            <div class="customers-slider__item" @click="goToCustomerPage(4, 'kyivcitycouncil', 'kyiv_infoscreens')" @mouseover="customersImg[4-1].hoovered = 4" @mouseleave="customersImg[4-1].hoovered = null">
                                <img class="customers-slider__itemImg" :src="customersImg[4-1].hoovered === 4 ? customersImg[4-1].color : customersImg[4-1].white" alt="png">
                            </div>
                             <div class="customers-slider__item" @click="goToCustomerPage(5, 'kpt', 'traffic_system')">
                                <img class="customers-slider__itemImg" :src="require(`@/assets/img/customers/5.png`)" alt="png">
                            </div>
                        </div>
                         <div class="customers-slider__item" @click="goToCustomerPage(6, 'as_vydubichi', 'bus_ticket_system')">
                            <img class="customers-slider__itemImg" :src="require(`@/assets/img/customers/6.png`)" alt="png">
                        </div>
                    </div>
                    <div class="customers-slider__itemWrapper">
                        <div class="customers-slider__row">
                            <div class="customers-slider__item" @click="goToCustomerPage(7, 'uaf', 'reports_system')">
                                <img class="customers-slider__itemImg" :src="require(`@/assets/img/customers/7.png`)" alt="png">
                            </div>
                             <div class="customers-slider__item" @click="goToCustomerPage(8, 'upl', 'website')">
                                <img class="customers-slider__itemImg" :src="require(`@/assets/img/customers/8.png`)" alt="png">
                            </div>
                        </div>
                         <div class="customers-slider__item" @click="goToCustomerPage(9, 'president-hotel', 'information_screens')">
                            <img class="customers-slider__itemImg" :src="require(`@/assets/img/customers/9.png`)" alt="png">
                        </div>
                    </div>

                    <template #prevArrow>
                        <div class="customers-slider__prevButton">
                            <img src="@/assets/img/back.svg" alt="svg">
                        </div>

                    </template>

                    <template #nextArrow>
                        <div class="customers-slider__nextButton">
                            <img src="@/assets/img/back.svg" alt="svg">
                        </div>

                    </template>

                    
                </VueSlickCarousel>

                <!-- <div v-for="index in customersList" :key="index" class="customers__item"
                    :class="{'customers__item--light': index % 2 === 0 || isMobile, 'customers__item--dark': index % 2 !== 0 && !isMobile }">
                    <img :src="require(`@/assets/img/customers/${index}.png`)" alt="png">
                </div> -->

            </div>
        </div>
    </div>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {

    components: {
        VueSlickCarousel
    },

    data() {
        return {
            customersList: [1, 2, 3, 4],
            settings: {
                infinite: false,
                arrows: true,
                speed: 100,
                slidesToShow: 2,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ],
            },

            customersImg: [
                null,
                null,
                {
                    hoovered: null,
                    color: require(`@/assets/img/customers/3.png`),
                    white: require(`@/assets/img/customers/3_1.png`)
                },
                {
                    hoovered: null,
                    color: require(`@/assets/img/customers/4.png`),
                    white: require(`@/assets/img/customers/4_1.png`)
                }
            ]
        }
    },

    methods: {
        goToCustomerPage(id, routeName, serviceName = null) {
            if (this.isMobile) {
                this.$router.push({ name: 'CustomerMobile', params: { id, name: routeName, item: 1, serviceName } }).catch(() => {})
            }
            else {
                this.$router.push({ name: 'Customer', params: { id, name: routeName, item: 1, serviceName } }).catch(() => {})
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.customers {

    .section__content {
        @media screen and (max-width: $lg) {
            padding: 50px 0;
        }

    }
    
    .section__left {
        @media screen and (max-width: $xl) {
            padding-bottom: 600px;
        }
        
        @media screen and (max-width: $lg) {
            padding-bottom: 0;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            padding-top: 70px;
        }
    }

    .section__content {
        @media screen and (max-width: $lg) {
            flex-direction: column;
            background-color: $color_black;
        }
    }

    &__list {
        position: absolute;
        padding: 0 12vw;
        margin-top: 330px;
        z-index: 2;
        width: calc(100vw - 24vw);

        @media screen and (min-width: $fullhd + 1px) {
            margin-top: 32vh;
        }

        @media screen and (max-width: $xl) {
            margin-top: 315px; 
            padding: 0 40px;
            width: calc(100vw - 32vw + 100px);
        }

        @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 900px) {
            margin-top: 280px;
            padding: 0 calc(12vw - 50px);
            margin-left: 0;
            margin-right: 0;
            box-sizing: border-box;
            width: 100%;
        }

        @media screen and (max-height: 901px) and (max-width: 1441px) {
            padding: 0 calc(16vw - 50px);
        }

        @media screen and (max-width: $lg) {
            position: relative;
            padding: 0 20px;
            margin: 0;
            padding-top: 40px;
            width: calc(100vw - 40px);
            padding-bottom: 100px;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            margin-top: 25vh;
        }
    }

}

.customers-slider {

    $r: '.customers-slider';

    &__itemWrapper {
        display: flex;
        flex-direction: row;

        > div {
            width: calc(100% - 100px);

            @media screen and (max-width: $xl) {
                width: calc(100% - 50px);
            }

            @media screen and (max-width: $lg) {
                width: 100%;
            }
        }
    }

    &__row {
        display: flex;
        align-items: center;

        #{$r}__item {
            width: 50%;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $color_red;
        transition: .1s;
        flex: 1;
        box-sizing: border-box;
        align-items: center;
        padding: 30px;
        justify-content: center;
        cursor: pointer;
        min-height: 240px;

        &:hover {
            background-color: #96253e;
        }

        & + & {
            margin-left: 20px;
        }

        @media screen and (max-height: 901px) and (max-width: 1441px) {
            min-height: 20vh;
            height: 20vh;
        }

        #{$r}__itemImg {
            height: auto;
            width: auto;
            max-width: 100%;
            max-height: 100px;

            @media screen and (max-height: 901px) and (max-width: 1441px) {
                transform: scale(0.7);
            }
        }
    }

    &__prevButton {
        left: -120px;

        @media screen and (max-height: 901px) and (max-width: 1441px) {
            left: -80px;
        }

        @media screen and (max-width: $lg) {
            left: calc(50% - 55px);
            top: calc(100% + 50px);
        }
    }

    &__nextButton {
        right: -120px;
        
        img {
            transform: rotate(180deg);
        }

        @media screen and (max-height: 901px) and (max-width: 1441px) {
            right: -80px;
        }

        @media screen and (max-width: $lg) {
            right: calc(50% - 55px);
            top: calc(100% + 50px);
        }
    }
}
</style>

<style lang="scss">

    $r: '.customers-slider';

    .slick-active {

        #{$r}__itemWrapper {
            > div {
                margin-right: auto;

                &:first-child {
                    margin-bottom: 40px;

                    @media screen and (max-width: $lg) {
                        margin-bottom: 20px;
                    }
                }
            }

            #{$r}__item {
                background-color: #41445299;

                #{$r}__itemImg {
                    filter: brightness(0) invert(1);
                }

                &:hover {
                    #{$r}__itemImg {
                        filter: none;
                    }
                }
            }
        }

        & ~ & {
            #{$r}__itemWrapper {
                > div {
                    margin-right: 0;
                    margin-left: auto;
                }

                #{$r}__item {
                    background-color: #f8f9fa;

                    #{$r}__itemImg {
                        filter: brightness(0)
                    }

                    &:hover {
                        #{$r}__itemImg {
                            filter: none;
                        }
                    }

                    @media screen and (max-width: $lg) {
                        background-color: #41445299;
                        height: 200px;
                        justify-content: center;
                
                        #{$r}__itemImg {
                            filter: brightness(0) invert(1);
                        }
                    }
                }
            }
        }
        
    }

    .slick-arrow {
        background-color: $color_red;
        height: 80px;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        &:hover {
            background-color: $color_red;
        }

        &::before {
            display: none;
        }

        img {
            height: 25px;
            filter: invert(1);
        }

        @media screen and (max-height: 901px) and (max-width: 1441px) {
            height: 50px;
            width: 50px;
        }
    }

    .slick-disabled {
        opacity: .3;
        cursor: default !important; 
    }
</style>
