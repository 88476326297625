<template>
    <div class="section main">
        <div class="section__content">
            <div class="section__left">
                <video ref="main__video" autoplay muted loop playsinline class="section__leftVideo">
                  <source src="@/assets/video/glitch1.mp4" type="video/mp4">
                </video>
            </div>
            <div class="section__right"></div>
            <div class="main__content">
                <img class="main__contentImg" src="@/assets/img/main_display.png" alt="png">
                
                <div class="main__contentMain">
                    <img class="main__contentLogo" v-if="!isMobile" src="@/assets/img/ster_logo_large.svg" alt="svg">
                    <img class="main__contentLogo" v-else src="@/assets/img/logo_small.svg" alt="svg">
                    <div class="main__contentText">
                        <h4 class="typography typography--h4">
                            {{ $t('sections.main.Are experts in') }}
                            <br>
                            <span class="typed-text">{{ typeValue }}</span>
                            <span class="cursor" :class="{'typing': typeStatus}">&nbsp;</span>
                        </h4>
                    </div>
                </div>
            </div>
            <a @click="$emit('goDown', true)" v-if="!isMobile" class="main__mouse"><img src="@/assets/img/mouse.svg" alt="svg"></a>
        </div>
    </div>
</template>

<script>

export default {
    data: () => {
      return {
        typeValue: '',
        typeStatus: false,
        typingSpeed: 50,
        typeArray: [],
        erasingSpeed: 0,
        newTextDelay: 2000,
        typeArrayIndex: 0,
        charIndex: 0
      }
    },

    created() {
        this.typeArray = [this.$t('navigation.Business Analysis & Consulting'), this.$t('navigation.Digital Transformation'), this.$t('navigation.Solutions Integration'), this.$t('navigation.Custom Software Development'), this.$t('navigation.Mobile Solutions'), this.$t('navigation.Software Design, Architecture, and Prototyping')];
        setTimeout(this.typeText, this.newTextDelay + 200);
    },

    methods: {
        typeText() {
            if(this.charIndex < this.typeArray[this.typeArrayIndex].length) {
                if(!this.typeStatus)
                this.typeStatus = true;
                this.typeValue += this.typeArray[this.typeArrayIndex].charAt(this.charIndex);
                this.charIndex += 1;
                setTimeout(this.typeText, this.typingSpeed);
            }
            else {
                this.typeStatus = false;
                setTimeout(this.eraseText, this.newTextDelay);
            }
        },
        eraseText() {
            if(this.charIndex > 0) {
                if(!this.typeStatus)
                    this.typeStatus = true;
                this.typeValue = this.typeArray[this.typeArrayIndex].substring(0, this.charIndex - 1);
                this.charIndex -= 1;
                setTimeout(this.eraseText, this.erasingSpeed);
            }
            else {
                this.typeStatus = false;
                this.typeArrayIndex += 1;
                if(this.typeArrayIndex >= this.typeArray.length)
                    this.typeArrayIndex = 0;
                setTimeout(this.typeText, this.typingSpeed + 1000);
            }
        }
    }
};
</script>

<style lang="scss" scoped>

.main {
    overflow: hidden;
    z-index: 10;

    @media screen and (max-width: $xl) {
        .section__left {
            min-height: 100vh;
        }
    }

    &__content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &__contentImg {
        position: absolute;
        left: 41%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 72vh;
        z-index: 2;

        @media screen and (max-width: $lg) {
            left: auto;
            right: -34vw;
            top: 0;
            height: 60vh;
            z-index: 2;
            opacity: .6;
            transform: none;
        }

        @media screen and (max-width: $sm) {
            right: auto;
            left: 20vw;
            top: -16vh;
            height: 60vh;
            z-index: 2;
            opacity: .6;
            transform: none;
        }
    }

    &__contentMain {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        margin-left: 40px;

        @media screen and (max-width: $lg) {
            padding: 0 20px;
            margin-left: 0;
            left: 0;
            transform: translate(0, 0);
            top: auto;
            bottom: 130px;
        }

        @media screen and (max-width: $sm) {
            padding-bottom: 160px;
            bottom: 80px;
        }
    }

    &__contentLogo {
        /*width: 650px;*/

        @media screen and (max-width: $lg) {
            width: 80vw;
            max-width: 350px;
        }
    }

    &__contentText {
        margin-right: 0;
        width: 350px;
        max-width: 100%;
        position: absolute;
        right: -80px;

        @media screen and (max-width: $lg) {
            position: relative;
            left: 0;
            width: 100%;
            right: auto;
        }

        @media screen and (max-width: $sm) {
            position: absolute;
            left: 20px;
        }

         @media screen and (min-width: $fullhd - 1px) {
            width: 650px;
            right: -380px;
        }

        h4 {
            font-size: 26px;
            letter-spacing: 2.78px;
            line-height: 40px;
            margin-top: 25px;

            @media screen and (max-width: $xxl) {
                font-size: 20px;
            }

            @media screen and (max-width: $sm) {
                line-height: 30px;
                font-size: 16px;
            }

            span.typed-text {
                text-transform: uppercase;
                color: $color_red;
            }
            span.cursor {
                display: inline-block;
                width: 4px;
                background-color: #dce3e4;
                animation: cursorBlink 1s infinite;
            }
            span.cursor.typing {
                animation: none;
            }

            @media screen and (max-width: $sm) {
                span {
                    font-size: 20px;
                }
            }
        }

        @keyframes cursorBlink {
            49% { background-color: #dce3e4; }
            50% { background-color: transparent; }
            99% { background-color: transparent; }
        }
    }

    &__mouse {
        position: absolute;
        left: 50%;
        bottom: 40px;
        cursor: pointer;
        z-index: 2;
        transform: translate(-50%, 0);

    }
}
</style>
