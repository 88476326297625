<template>
    <div>
        <transition name="fade">
            <Preloader v-if="preloader" />
        </transition>

        <Navigation ref="navigation" @changeLocale="changeLocale" />
        
        <div class="section one-customer" :key="remountKey">
            <div class="section__content">
                <div class="one-customer__ripples" :style="{ 
                    backgroundColor: '#01050f'}">
                </div>
                <div class="section__left">
                    <h2 class="typography typography--h2 section__title">
                        <span class="red">{{ title }}</span>
                    </h2>

                    <div class="one-customer__figure"></div>

                    <div class="one-customer-cases">
                        <div class="one-customer-cases__item"
                            :class="itemId == c ? 'one-customer-cases__item--active' : ''"
                            v-for="(c, index) in casesLength" 
                            :key="index" 
                            @click="itemClick(c)"
                        >
                            <div class="one-customer-cases__circle"></div>
                            <h4 class="one-customer-cases__title">{{ $t(`pages.one-customer.Customer${customerId}[${index}]`) }}</h4>
                        </div>
                    </div>

                    <!-- <div class="one-customer__bookmarks">
                        <img src="@/assets/img/bookmark.svg" alt="bookmark">
                        <span v-if="customerId == 5">Event</span>
                        <span v-else>Product</span>
                    </div> -->
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* Waves */

import Navigation from "@/components/Navigation";
import Preloader from "@/components/Preloader"

export default {
    name: "CustomerMobile",
    components: {
        Navigation,
        Preloader
    },

    data() {
        return {
            title: "",
            preloader: true,
            stepHidden: true,
            remountKey: 0,
            itemId: 1
        };
    },

    computed: {
        customerId() {
            if (!this.$route.params?.id) {
                if (this.$route.params.name == 'es') return 1
                if (this.$route.params.name == 'decom') return 2
                if (this.$route.params.name == 'overprint') return 3
                if (this.$route.params.name == 'kyivcitycouncil') return 4
                if (this.$route.params.name == 'kpt') return 5
                if (this.$route.params.name == 'as_vydubichi') return 6
                if (this.$route.params.name == 'uaf') return 7
                if (this.$route.params.name == 'upl') return 8
                if (this.$route.params.name == 'president-hotel') return 9
            }
            return this.$route.params.id
        },

        casesLength() {
            switch (this.customerId) {
                case 1:
                    return 2
                break;
                case 2:
                    return 1
                break;
                case 3:
                    return 1
                break;
                case 4:
                    return 2
                break;
                case 5:
                    return 3
                break;
                case 6:
                    return 1
                break;
                case 7:
                    return 2
                break;
                case 8:
                    return 2
                break;
                case 9:
                    return 1
                break;
            }
        }
    },

    watch: {
        customerId(v) {
            this.getData(v, 0);
        },
    },

    mounted() {
        this.getData(this.customerId, 0);
        this.itemId = this.getItemIdByName(this.customerId)

        setTimeout(() => {
            this.preloader = false
        }, 700);

        if (!this.isMobile) {
            this.$refs.navigation.$refs.header__logo.style.opacity = 1
            this.$refs.navigation.$refs.header__logo.style.height = '31px'
        }
        
    },

    methods: {
        getData(customerId, customerItemId) {
            if (typeof customerId == 'string') {
                customerId = +customerId
            }

            this.title = this.$t('pages.one-customer.customer-main-title')[customerId-1]
            window.document.title = this.title + ' | STERKONIX'
        },

        getItemIdByName(customerId) {

            switch (customerId) {
                case 1:
                    if (this.$route.params.serviceName == 'es_platform') {
                        return 1
                    } else  {
                        return 2
                    }
                break;
                case 3:
                    return 1
                break;
                case 4:
                    if (this.$route.params.serviceName === 'kyiv_infoscreens') {
                        return 1
                    } else {
                        return 2
                    }
                    
                break;
                case 5:
                    if (this.$route.params.serviceName === 'traffic_system') {
                        return 1
                    } else if (this.$route.params.serviceName === 'website') {
                        return 2
                    } else if (this.$route.params.serviceName === 'brand_book') {
                        return 3
                    }
                
                break;
                case 6:
                    return 1
                break;
                case 7:
                    if (this.$route.params.serviceName === 'reports_system') {
                        return 1
                    } else {
                        return 2
                    }
                break;
                case 8:
                    if (this.$route.params.serviceName === 'website') {
                        return 1
                    } else {
                        return 2
                    }
                break;
                case 9:
                    return 1
                break;
            }
        },

        itemClick(id) {
            let serviceName = '';
            switch (this.$route.params.name) {
                case 'es':
                    if (id === 1) {
                        serviceName = 'es_platform'
                    } else if (id === 2) {
                        serviceName = 'eCast'
                    }
                break;
                case 'overprint':
                    if (id === 1) {
                        serviceName = 'online_calculator'
                    }
                break;
                case 'kyivcitycouncil':
                    if (id === 1) {
                        serviceName = 'kyiv_infoscreens'
                    } else if (id === 2) {
                        serviceName = 'LMS'
                    }
                break;
                case 'kpt':
                    if (id === 1) {
                        serviceName = 'traffic_system'
                    } else if (id === 2) {
                        serviceName = 'website'
                    } else if (id === 3) {
                        serviceName = 'brand_book'
                }
                break;
                case 'as_vydubichi':
                    if (id === 1) {
                        serviceName = 'bus_ticket_system'
                    }
                break;
                case 'uaf':
                    if (id === 1) {
                        serviceName = 'reports_system'
                    } else if (id === 2) {
                        serviceName = 'UAF_infoscreens'
                    }
                break;
                case 'upl':
                    if (id === 1) {
                        serviceName = 'website'
                    } else if (id === 2) {
                        serviceName = 'UPL_system'
                    }
                break;
                case 'president-hotel':
                    if (id === 1) {
                        serviceName = 'information_screens'
                    }
                break;
            }
            this.$router.replace({ name: 'Customer', params: { id: this.$route.id, name: this.$route.params.name, item: id, serviceName } }).then(()=> {
                window.document.title = this.title + ' | STERKONIX';
                this.itemId = this.$route.params?.item
            }).catch(() => {})
        },

        changeLocale() {
            setTimeout(() => {
                this.getData(this.$route.params.id)
            }, 100);
        }
    },

   
};
</script>

<style lang="scss" scoped>

.one-customer {

    .section__content {
        min-height: 100vh;

        @media screen and (max-width: $lg) {
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    .section__title {
        position: relative;
        font-size: 40px;
        padding-top: 140px !important;

        span {
            color: white;
        }
    }

    .section__right {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;

        @media screen and (max-width: $lg) {
            width: 100vw;
            position: absolute;
            margin-top: 500px;
        }
    }

    &__figure {
        background-image: url("~@/assets/img/services.png");
        background-position: center;
        background-size: cover;
        opacity: .4;
        height: 500px;
        width: 500px;
        left: -25vw;
        top: -10vh;
        z-index: 2;
        position: absolute;
        background-repeat: no-repeat;
    }

/*     &__bookmarks {
        background-color: #3438397d;
        padding: 11px 15px;
        color: white;
        border-radius: 4px;
        display: flex;
        align-items: center;
        z-index: 222;
        position: absolute;
        width: fit-content;
        font-size: 12px;

        @media screen and (max-width: $lg) {
            top: 90px;
        }

        img {
            margin-right: 10px;
            height: 20px;
        }
    } */

    

    .section__left {
        padding-bottom: 70px;
        overflow: hidden;

    }

    &__content {
        margin: 120px 12vw 40px 4vw;
        margin-right: 0;
        padding-right: 4vw;
        overflow: auto;

        @media screen and (max-width: $lg) {
            margin: 40px 0 40px 20px;
        }
    }

    &__itemLogo {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: 20px;
        height: 80px;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 80px;
        }
    }

    &__ripples {
        height: 100vh;
        width: 50vw;
        position: fixed;
        left: 0;
        top: 0;
        background-position: center;
        background-size: 100%;
        transition: .3s;
        background-color: $color_black;

        @media screen and (max-width: $lg) {
            width: 100vw;
            height: 100vh;
            position: absolute;
        }
    }

    &__item {
        h4 {
            margin-top: 40px;
            color: $color_red;
        }
    }
}

.one-customer-cases {
    display: flex;
    flex-direction: column;
    padding-right: 4vw;
    position: relative;
    padding-top: 40px;
    z-index: 100;


    &__item {
        padding: 50px 40px;
        display: flex;
        align-items: flex-start;
        background-color: #7a7b8345;
        border-bottom: 1px solid $color_red;
        cursor: pointer;
        transition: .3s;

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: $lg) and (min-height: 595px) {
            padding: 30px 20px;
        }

        &:hover {
            background-color: #8b0f22c4;
        }

        & + & {
            margin-top: 20px;
        }
    }

    &__title {
        font-size: 26px;
        font-weight: bold;
        letter-spacing: 1.63px;
        color: white;
        margin: 0;

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: $lg) and (min-height: 595px) {
            font-size: 16px;
        }
    }
}
</style>
