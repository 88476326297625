export default {

    /* navigation */
    navigation: {
        'main': 'main',
        'about 3k' : 'about sterkonix',
        'solutions' : 'solutions',
        'vacancies' : 'vacancies',
        'contacts' : 'contacts',
        'customers' : 'customers',
        'presentations' : 'presentations',
        'services' : 'services',

        'Business Analysis & Consulting' : 'Business Analysis & Consulting',
        'Digital Transformation' : 'Digital Transformation',
        'Solutions Integration' : 'Solutions Integration',
        'Custom Software Development' : 'Custom Software Development',
        'Mobile Solutions' : 'Mobile Solutions',
        'Software Design, Architecture, and Prototyping' : 'Software Design, Architecture, and Prototyping',

        'Presentation' : 'Presentation',

        'videos' : 'videos',

        'back' : 'Back',
    },


    sections: {
        main: {
            'Are experts in': 'Are experts in',
            'Consulting' : 'Consulting',
            'Audit' : 'Technical audit',
            'Development' : 'Development',
            'Integrations' : 'Software & hardware integration',
            'Mobile applications' : 'Mobile applications',
        },

        howWorking: {
            'How we' : 'Way we',
            'are working' : 'do',
            'STEP' : 'STEP',
            
            'Design – Prototyping – Technical Requirements' : 'Design – Prototyping – Technical Requirements',
            'Backlog – Estimating – Prioritization' : 'Backlog – Estimating – Prioritization',
            'Developing – Testing – Deploying' : 'Developing – Testing – Deploying',
        },

        solutions: {
            'Our' : 'Our',
            'solutions' : 'solutions',
            'Solution1' : 'Patentem',
            'Solution2' : 'Elect.Pro',
            'Solution3' : 'Scientia',
            'Solution4' : 'Bus station management system',
            'Solution5' : 'Forum “Digitalization of politics”',

            'About company caption' : 'About company',
            'Company description' : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae doloribus quod eveniet ipsam quidem obcaecati.[read more link]',
            
            'About solution1 caption' : 'About product',
            'Solution1 description' : 'Complex information system for sessions, meetings and voting that complies to high standards and requirements of the public and corporate sector.',
            
            'About solution2 caption' : 'About product',
            'Solution2 description' : 'Brand new political campaign software for party organizations and political players which makes it easy to hold a campaign at all stages, communicate with electorate, logging all types of cooperation and etc',
            
            'About solution3 caption' : 'About product',
            'Solution3 description' : 'E-learning web-based solution that covers all needs in training, testing and certification of employees, customers, partners, dealers and other contractors with modern tools using a smartphone, tablet and personal computer.',
            
            'About solution4 caption' : 'About product',
            'Solution4 description' : 'Information and navigation system for event visitors, with the functionality of ticket sales and registration process support',

            'About solution5 caption' : 'About event',
            'Solution5 description' : 'Forum “Digitalization of politics”- is a unique event will provide an opportunity to discuss and understand all the latest global changes in political technologies.',
        },

        services: {
            'Our' : 'Our',
            'services' : 'services',
            '1Description' : 'evaluating your technology choices, optimising your processes and frame. Offering solutions that can boost your business, making it more efficient, streamlined and profitable.',
            '2Description' : 'building intelligent digital solutions to automate manual and inefficient business processes.',
            '3Description' : 'hardware and software integration to build the complex ecosystem, making it easy to scale, adapt and adjust as business requirements change.',
            '4Description' : 'creating new digital products (mobile & web) at the intersection of market opportunity, customer experience and emerging technology.',
            '5Description' : 'building intuitive and reliable applications for iOS and Android, Chat Bots development for ​​ready access to information and channel integration with Telegram, Facebook, Whatsapp, Viber.',
            '6Description' : 'designing intuitive digital experiences that are optimised across web, tablet, and mobile devices using the best UI/UX practices. ',
            'All services' : 'All services'
        },

        vacancies: {
            'Our' : 'Our',
            'vacancies' : 'vacancies',
            'list' : [
                {
                    'title' : 'Middle PHP Developer (Laravel)',
                    'resp' : [
                        'Expertise in object-oriented programming in PHP',
                        'Laravel',
                        'PHP, MySQL, JavaScript, NodeJS, Docker, jQuery'
                    ]
                },
                {
                    'title' : 'Middle PHP Developer (YII2)',
                    'resp' : [
                        'Expertise in object-oriented programming in PHP',
                        'YII2',
                        'PHP, MySQL, JavaScript, NodeJS, Docker, jQuery'
                    ]
                },
                {
                    'title' : 'Front-end developer',
                    'resp' : [
                        'Vue.js',
                        'Converting wireframes, prototypes, sketches, and high-fidelity mockups into usable and responsive applications',
                        'Writing semantic, accessibility minded, and scalable development'
                    ]
                },
                {
                    'title' : 'Middle QA Engineer (Manual)',
                    'resp' : [
                        'Develop acceptance test criteria for our solutions and products',
                        'Create manual tests to ensure we constantly deliver quality products',
                        'Develop and monitor performance metrics'
                    ]
                }
            ],
            'Respond' : 'Respond',
            'Hide' : 'Hide',
        },

        about: {
            'About' : 'About',
            'title' : 'Lorem ipsum dolor sit amet',
            'p' : [
                `Product and Software Development company. Our team is inspired to provide full cycle development services - web application architecture, UX/UI design, development, QA, deployment.`,
                `To every project, we bring a combination of domain expertise and exceptional engineering talent of our prof developers.`,
                `Sterkonix applies a full agile methodology, not only for software development, but in building company culture too. Our teams are not afraid of taking on new challenges and encouraged in non-standard requests.`
            ],
            'Contact' : 'Contact'
        },

        contacts: {
            'Our' : 'Our',
            'contacts' : 'contacts',
            'Contact us' : 'Contact us',
            'form' : {
                'Name' : 'Name',
                'Name error' : 'Please enter the correct username',
                'E-mail' : 'E-mail',
                'E-mail error' : 'Please enter the correct email',
                'Phone' : 'Phone',
                'Phone error' : 'Please enter the correct phone number',
                'Type your message' : 'Type your message',
                'Type your message error' : 'Empty message'
            },
            'Contact' : 'Contact'
        },

        customers: {
            'Our' : 'Our',
            'customers' : 'customers',
        },

        footer: {
            'Services:' : 'Services:',
            'Solutions:' : 'Solutions:',
        }
    },


    /* inner pages */
    pages: {
        service: {
            'Consulting' : 'Consulting',
            'Business analysis' : 'Business analysis',
            'Audit' : 'Audit',
            'Development' : 'Development',
            'Integrations' : 'Integrations',
            'Mobile apps' : 'Mobile applications',
        },

        'one-customer': {
            'customer-main-title' : [
                'Political Party European Solidarity',
                'DECOM',
                "Overprint",
                "Kyiv City Council",
                'Transport company Kyivpastrans',
                'Vydubychi Bus Station',
                'Ukrainian Assoсiation of Football',
                'The Ukrainian Premier League',
                'President Hotel'
            ],

            'Customer1': [
                'ES Platform',
                'eCast - voting day accompanying system'
            ],

            'Customer3': [
                'Online calculator'
            ],

            'Customer4': [
                'System for informing citizens about the services of Kyiv tourist attractions',
                'Learning management system'
            ],

            'Customer5': [
                'System for informing passengers about traffic',
                'Kyivpastrans website',
                'Kyivpastrans brand book'
            ],

            'Customer6': [
                'Automatized system of bus tickets booking and selling'
            ],

            'Customer7': [
                'Reports system of UAF security officers and match directors',
                'UAF information screens'
            ],

            'Customer8': [
                'The Ukrainian Premier League website',
                'System of the Ukrainian Premier League',
            ],
            
            'Customer9': [
                'Information screens for visitors navigation and notification'
            ],
            
            
            
        }
    }


/*     '' : '',*/

}