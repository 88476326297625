<template>
    <div class="section about">
        <div class="section__content">
            <div class="section__left">
                <h2 class="typography typography--h2 section__title">
                    <span class="white">{{ $t('sections.about.About') }} </span>
                    <br />
                    <span class="red">STER<span class="white"></span>KONIX</span>
                </h2>
                <div class="about__figure"></div>
                <video ref="about__video" autoplay muted loop playsinline class="section__leftVideo">
                  <source src="@/assets/video/glitch1.mp4" type="video/mp4">
                </video>
            </div>
            <div class="section__right">
                <!-- <h4 class="typography typography--h4">{{ $t('sections.about.title') }}</h4> -->
                <p class="typography typography--paragraph">
                    {{ $t('sections.about.p[0]') }}
                </p>
                <p class="typography typography--paragraph">
                    {{ $t('sections.about.p[1]') }}
                </p>
                 <p class="typography typography--paragraph">
                    {{ $t('sections.about.p[2]') }}
                </p>
                <ButtonComponent @click="$emit('goContacts')" class="about__button" :caption="$t('sections.about.Contact')"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.about {

    .section__left {

        /* @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            padding-top: 70px;
        } */
    }

    .section__right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 60px;

        @media screen and (max-width: $xl) {
            padding-top: 70px;
        }

        @media screen and (max-height: $md) {
            justify-content: flex-start;
        }

        @media screen and (max-height: $xxl) {
            padding-bottom: 10px;
            justify-content: center;

            p {
                font-size: 18px;
            }
        }

        @media screen and (max-width: $lg) {
            position: relative;
            width: 100vw;
            padding: 20px;
            background-color: transparent;
            padding-top: 40px;
            padding-bottom: 100px;
        }
    }

    .section__content {
        @media screen and (max-width: $lg) {
            flex-direction: column;
            background-color: $color_black;
        }
    }

    &__figure {
        background-image: url("~@/assets/img/about_back.png");
        background-position: center;
        background-size: cover;
        opacity: .6;
        height: 700px;
        width: 700px;
        left: 23vw;
        top: 50vh;
        z-index: 2;
        position: absolute;
        background-repeat: no-repeat;

        @media screen and (min-width: $fullhd + 1px) {
            height: 900px;
            width: 900px;
        }

        @media screen and (max-width: $xxl) {
            height: 400px;
            width: 400px;
            left: 23vw;
            top: 66vh;
        }

        @media screen and (max-width: $xl) {
            top: 41vh;
        }
    }

    &__button {
        margin-top: 60px;

        @media screen and (max-width: $lg) {
            margin-top: 40px;
        }
    }
}
</style>
