import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isMobile: 'desktop',
    },
    getters: {
        getResolution(state) {
            return state.isMobile
        }
    },
    mutations: {
        setResolution(state, p) {
            state.isMobile = p
        }
    },
    actions: {

    },
    modules: {}
});
