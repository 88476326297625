<template>
  <div class="preloader">
    <svg version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="25 25 50 50">
      <circle cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke="#fff" stroke-linecap="round" stroke-dashoffset="0" stroke-dasharray="100, 200">
        <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 50 50" to="360 50 50" dur="2.5s" repeatCount="indefinite"/>
        <animate attributeName="stroke-dashoffset" values="0;-30;-124" dur="1.25s" repeatCount="indefinite"/>
        <animate attributeName="stroke-dasharray" values="0,200;110,200;110,200" dur="1.25s" repeatCount="indefinite"/>
      </circle>
    </svg>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
    .preloader {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color_black;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 10000;

        svg {
            width: 30px;
        }
    }
</style>