<template>
    <div>
        <transition name="fade">
            <Preloader v-if="preloader" />
        </transition>

        <Navigation ref="navigation" @changeLocale="changeLocale" />
        
        <div class="section one-customer" :key="remountKey">
            <div class="section__content">
                <div class="one-customer__ripples" v-if="!preloader" :style="{ 
                    backgroundImage: 'url(' + require(`@/assets/img/customers/one-customer/${customerId}.${itemId}.jpg`) + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center' }">
                </div>
                <div class="section__left">
                    <h2 class="typography typography--h2 section__title">
                        <span class="red">{{ title }}</span>
                    </h2>

                    <div class="one-customer-cases" v-if="!isMobile">
                        <div class="one-customer-cases__item"
                            :class="itemId == c ? 'one-customer-cases__item--active' : ''"
                            v-for="(c, index) in casesLength" 
                            :key="index" 
                            @click="itemClick(c)"
                        >
                            <div class="one-customer-cases__circle"></div>
                            <h4 class="one-customer-cases__title">{{ $t(`pages.one-customer.Customer${customerId}[${index}]`) }}</h4>
                        </div>
                    </div>

                    <!-- <div class="one-customer__bookmarks">
                        <img src="@/assets/img/bookmark.svg" alt="bookmark">
                        <span v-if="customerId == 5">Event</span>
                        <span v-else>Product</span>
                    </div> -->
                    
                </div>
                <div class="section__right">
                    <div class="one-customer__content">
                        <div class="one-customer__itemLogo">
                            <img :src="require(`@/assets/img/customers/${customerId}.png`)" alt="png">
                        </div>

                        <div class="one-customer__item" v-if="customerId == 1 && itemId == 1">
                            <div class="one-customer__itemLogo">
                                <img :src="require(`@/assets/img/customers/${customerId}_3.png`)" alt="png">
                            </div>

                            <h4 class="typography typography--h4">{{ $t(`pages.one-customer.Customer${customerId}[${itemId-1}]`) }}</h4>
                            <p class="typography typography--paragraph">The ES Platform is a digital solution for work coordinating deputies of all levels and party members.
                            The functional components are aimed to implement the main tasks of the party and deputies online. The decision allows coordinating the work of deputies, facilitates the establishment of continuous communication with them and provides timely instruction, informing the team about urgent issues and respond to informational causes.</p>
                            <p class="typography typography--paragraph">This is a modern solution that is able to process large amounts of data and automate processes of national importance.</p>

                            <p class="typography typography--paragraph"><b>Among the main functions of the platform:</b></p>
                            <ul class="base">
                                <li>Party positioning;</li>
                                <li>Formation of ratings of deputies of all levels;</li>
                                <li>Incentive system for party members;</li>
                                <li>Placement of information, including announcements, infographics, survey results, etc ;</li>
                                <li>Coordinating the work of deputies of all levels;</li>
                                <li>Instruction for party members;</li>
                                <li>Timely communication through the information channel between the central and local branches of the party;</li>
                                <li>Collection the database;</li>
                                <li>Online learning;</li>
                                <li>Online consultations;</li>
                                <li>Collection of feedback, opinions, appeals and suggestions.</li>
                            </ul>
                        </div>

                        <div class="one-customer__item" v-if="customerId == 1 && itemId == 2">
                            <div class="one-customer__itemLogo">
                                <img :src="require(`@/assets/img/customers/${customerId}_3.png`)" alt="png">
                            </div>

                            <h4 class="typography typography--h4">{{ $t(`pages.one-customer.Customer${customerId}[${itemId-1}]`) }}</h4>

                            <p class="typography typography--paragraph">An automated system that provides constant access to operational information on each PEC and allows control of all election stages. The system is available even on phones and tablet computers.
                            eCast gives the opportunity to control the information flow from thousands of voting points simultaneously, to keep in touch with observers and the headquarters team.</p>
                            
                        </div>


                        <div class="one-customer__item" v-if="customerId == 3">

                            <h4 class="typography typography--h4">{{ $t(`pages.one-customer.Customer${customerId}[${itemId-1}]`) }}</h4>

                            <p class="typography typography--paragraph">Our team has created a flexible tool for the Overprint printing house, which operates with digital and large-format printing, for convenient calculation of the cost of printing services for printing products.</p>
                            <p class="typography typography--paragraph">The main function of the system is to create a calculator that has many variables at the input and and at the same time provides the desired result. The system allows you to create and configure the necessary calculation formulas for different types of products, from printing ordinary files to large-scale work using lamination, creasing and plotter cutting.</p>

                        </div>


                        <div class="one-customer__item" v-if="customerId == 4 && itemId == 1">

                            <h4 class="typography typography--h4">{{ $t(`pages.one-customer.Customer${customerId}[${itemId-1}]`) }}</h4>

                            <p class="typography typography--paragraph">“Kyiv Information terminals” is a web-oriented software aimed at informing citizens about the services of tourist attractions in Kiev, their operating hours, reference phones, websites, location and the forming of a route map.</p>
                            <p class="typography typography--paragraph">The reasons behind the implementation of “Kyiv Information terminals” system were to create an environment, where each user will have the opportunity to receive up-to-date information about various events and fascinating tourist sites in Kyiv. The system contains information about more than 1000 places of interest in Kyiv, including monuments, cathedrals, parks, theaters etc., which allows you to stay tuned for the latest information regarding the cultural life of the capital.</p>
                            <p class="typography typography--paragraph">A unique feature of the software is the customization of the tourist route to the unique needs of the user. An interactive map of the city with all tourist sites, where user can mark the places of interest and the system will form the most convenient route and send it to the user’s e-mail, is implemented in the system.</p>

                        </div>
                        <div class="one-customer__item" v-if="customerId == 4 && itemId == 2">

                            <h4 class="typography typography--h4">{{ $t(`pages.one-customer.Customer${customerId}[${itemId-1}]`) }}</h4>

                            <p class="typography typography--paragraph">Learning management system that gives staff an opportunity to gain knowledge simultaneously performing their duties in Kyiv City Council, helps to automate learning processes, give users an opportunity to improve skills, and management – receive various reports on the results of study.</p>
                            <p class="typography typography--paragraph">Course Designer – built-in designer of training courses with the addition of any multimedia materials and the use of webinars for online lectures and conference calls.</p>
                            <p class="typography typography--paragraph">Testing and Surveys – testing employees to verify acquired knowledge and providing well-timed certification. </p>
                            <p class="typography typography--paragraph">Statistics analysis – Analysis of statistical information on the results of training, which allows management  to prepare personal training and retraining plans for employees.</p>
                            <p class="typography typography--paragraph">Webinars - convenient tool for conducting training in the format of “live classes”: online video communication, an interactive whiteboard for common work and interaction with educational materials, the ability to give the floor to the listener, as well as general chat for communications.</p>
                        </div>

                        <div class="one-customer__item" v-if="customerId == 5 && itemId == 1">

                            <h4 class="typography typography--h4">{{ $t(`pages.one-customer.Customer${customerId}[${itemId-1}]`) }}</h4>

                            <p class="typography typography--paragraph">The Transport Online service provides users of the Kyivpastrans web portal with all the necessary information about public transport: stops, timetables, search for routes, and also allows you to monitor the movement of public transport online.</p>
                            <p class="typography typography--paragraph">The service builds optimal routes in automatic mode and predicts arrivals of public transport at stops.</p>
                            <p class="typography typography--paragraph">The use of websockets provides the fastest processing of large amounts of data for high-quality visualization of traffic and the operational display of the location of the transport on the map.</p>
                        </div>
                        <div class="one-customer__item" v-if="customerId == 5 && itemId == 2">
                            <h4 class="typography typography--h4">{{ $t(`pages.one-customer.Customer${customerId}[${itemId-1}]`) }}</h4>

                            <p class="typography typography--paragraph">The website design was developed in accordance with the principles and foundations of UX / UI design.</p>
                            <p class="typography typography--paragraph">It is equipped with an advanced administrative panel, which allows administrators to change the content, structure of menu and pages. The site’s functionality allows you to manage various types of appeals from users that have left using feedback forms.</p>
                        </div>
                        <div class="one-customer__item" v-if="customerId == 5 && itemId == 3">

                            <h4 class="typography typography--h4">{{ $t(`pages.one-customer.Customer${customerId}[${itemId-1}]`) }}</h4>

                            <p class="typography typography--paragraph">The brand book of Kyivpastrans company describes a set of artistic techniques and the rules for their application.</p>
                            <p class="typography typography--paragraph">Brand book sets the rules for stylistic and color solutions, defines the rules for using corporate style and visual brand identity.</p>
                        </div>

                        <div class="one-customer__item" v-if="customerId == 6">

                            <h4 class="typography typography--h4">{{ $t(`pages.one-customer.Customer${customerId}[${itemId-1}]`) }}</h4>

                            <p class="typography typography--paragraph">The system allows  creating a schedule in automatic mode, following the terms of cooperation with carriers, the configuration and size of the vehicle, the type of passenger registration, the number of available platforms etc. Due to a wide range of settings, the system provides ability to adjust the rules of sale, reissuing and return of tickets and luggage, as well as flexibly change the fare grid for each individual carrier.</p>
                            <p class="typography typography--paragraph">The system provides integration with fiscal registrars for high-quality and timely information exchange with internal accounting systems, which allows optimizing the work of the financial department and speed up the process of settlements with counterparties.</p>

                            <p class="typography typography--paragraph"><b>Advantages of use:</b></p>
                            <ul class="base">
                                <li>High coherence of bus station work and productivity of all bus station due to automation of work of cashiers, dispatchers, bookkeeping;</li>
                                <li>The system allows performing the entire range of work in automatic mode: from the introduction and verification of regulatory information, launching of the bus route for sale to the departure of the bus trip and the formation of documents for settlements with carrier companies;</li>
                                <li>Expended capabilities of traffic manager due to access to information on the all bus routes occupancy and ticket sales management;</li>
                                <li>Significant increase in productivity of cashiers and accounting;</li>
                                <li>Improving the quality of passenger service;</li>
                                <li>The operation of the complex does not require highly qualified personnel.</li>
                            </ul>

                        </div>

                        <div class="one-customer__item" v-if="customerId == 7 && itemId == 1">

                            <h4 class="typography typography--h4">{{ $t(`pages.one-customer.Customer${customerId}[${itemId-1}]`) }}</h4>

                            <p class="typography typography--paragraph">Reports system of UAF security officers and match directors is a system for automation of a process of reports filling out by security officers and match directors.</p>
                            <p class="typography typography--paragraph">Reports system of UAF security officers and match directors is a common environment for security officers, match directors and UAF management for exchange of reports, documents and other information regarding matches on the territory of Ukraine.</p>
                            <p class="typography typography--paragraph"><b>Advantages of the system:</b></p>

                            <ul class="base">
                                <li>Accumulation of information on games in the form of reports;</li>
                                <li>Accumulation of information on accredited stadiums in the form of a list of available documents required for their accreditation;</li>
                                <li>Reduction of the time for processing the data needed to security officers and match directors to prepare for matches and create reports;</li>
                                <li>Ability to access the system from any device and at any time;</li>
                                <li>Flexibility and availability of the system in terms of user levels and their capabilities in the system;</li>
                                <li>Two-level users' authentication ensures maximum protection of information;</li>
                                <li>Centralization of all the information necessary for the effective work of UAF security officers and match directors  in a unified system.</li>
                            </ul>

                        </div>
                        <div class="one-customer__item" v-if="customerId == 7 && itemId == 2">

                            <h4 class="typography typography--h4">{{ $t(`pages.one-customer.Customer${customerId}[${itemId-1}]`) }}</h4>

                            <p class="typography typography--paragraph"><b>UAF information screens is a web-oriented software aimed at informing staff and office visitors about:</b></p>

                            <ul class="base">
                                <li>current management with detailed bio on each member;</li>
                                <li>goblets 3d models with their history and meaning;</li>
                                <li>interactive football map;</li>
                                <li>event calendar with description;</li>
                                <li>other services.</li>
                            </ul>

                            <p class="typography typography--paragraph">Software provide possibility to authorize in the system with the use of personal QR-code to get access to personal information. </p>

                        </div>

                        <div class="one-customer__item" v-if="customerId == 8 && itemId == 1">

                            <h4 class="typography typography--h4">{{ $t(`pages.one-customer.Customer${customerId}[${itemId-1}]`) }}</h4>

                            <p class="typography typography--paragraph">Website was developed for the presentation of UPL activity. The website is fully integrated with the information system of UPL for displaying tournament tables, information about football players and clubs, match schedule. Data about transfers, conditions of purchase and contracts.</p>
                            <p class="typography typography--paragraph">It is equipped with an advanced administrative panel, which allows administrators to change the content, structure of menu and pages. The site’s functionality allows you to manage various types of appeals from users that have left using feedback forms.</p>

                        </div>
                        <div class="one-customer__item" v-if="customerId == 8 && itemId == 2">

                            <h4 class="typography typography--h4">{{ $t(`pages.one-customer.Customer${customerId}[${itemId-1}]`) }}</h4>

                            <p class="typography typography--paragraph">System of the Ukrainian Premier League is a system of complex information and analytical support for the activities of the Ukrainian Premier League.
                            System of the Ukrainian Premier League is a web-based system designed to process, exchange and control over the documents and other information related to the organization and conduct of competitions under the auspices of the Ukrainian Premier League.</p>

                            <p class="typography typography--paragraph"><b>Advantages of the system:</b></p>
                            <ul class="base">
                                <li>Creation of a common environment for the exchange of information between clubs, sponsors, arbitrators and relevant departments of the Ukrainian Premier League;</li>
                                <li>Automation of processes associated with the organization and conduct of competitions;</li>
                                <li>Reduction of time for data processing and creation of corresponding reports;</li>
                                <li>Systematization of UPL clubs’ data reporting to sponsors;</li>
                                <li>Control over adherence to UPL regulations;</li>
                                <li>Accumulation of information and subsequent analysis of matches;</li>
                                <li>Providing a standardized and user-friendly interface;</li>
                                <li>Increase of information capacity of the reports and standardization of reports formation process.</li>
                            </ul>

                        </div>

                        <div class="one-customer__item" v-if="customerId == 9">
                            <h4 class="typography typography--h4">{{ $t(`pages.one-customer.Customer${customerId}[${itemId-1}]`) }}</h4>

                            <p class="typography typography--paragraph">The system is designed to automate notifying visitors about events taking place in the hotel and simplify their navigation around the building.</p>

                            <p class="typography typography--paragraph"><b>The system provides the following processes:</b></p>
                            <ul class="base">
                                <li>creating and editing routes for the navigation of visitors;</li>
                                <li>provision and control of  attendees registration on event using QR codes;</li>
                                <li>managing the display of advertising materials on information screens;</li>
                                <li>statistics and analytics of occupation and usage of conference rooms;</li>
                                <li>remote monitoring and control of information screens.</li>
                            </ul>

                        </div>

                        <div class="one-customer__buttons" v-if="isMobile && !(!isPrevServiceButtonVisible && !isNextServiceButtonVisible)">
                            <ButtonComponent @click="setPreviousService" :disabled="!isPrevServiceButtonVisible" class="one-customer__button" :caption="'Previous'" />
                            <ButtonComponent @click="setNextService" :disabled="!isNextServiceButtonVisible" class="one-customer__button" :caption="'Next'" />
                        </div>
                        
                    </div>
                </div>

                <FastCustomers v-if="!isMobile" @fallback="fallbackServiceId"/>
            </div>
        </div>
    </div>
</template>

<script>
/* Waves */
import "@/plugins/jquery.ripples.js"

import Navigation from "@/components/Navigation";
import Preloader from "@/components/Preloader"
import ButtonComponent from "@/components/ButtonComponent"
import FastCustomers from "@/components/FastCustomers";

export default {
    name: "Customer",
    components: {
        Navigation,
        Preloader,
        ButtonComponent,
        FastCustomers
    },

    data() {
        return {
            title: "",
            preloader: true,
            stepHidden: true,
            remountKey: 0,
            itemId: 1
        };
    },

    computed: {
        customerId() {
            if (!this.$route.params?.id) {
                if (this.$route.params.name == 'es') return 1
                if (this.$route.params.name == 'decom') return 2
                if (this.$route.params.name == 'overprint') return 3
                if (this.$route.params.name == 'kyivcitycouncil') return 4
                if (this.$route.params.name == 'kpt') return 5
                if (this.$route.params.name == 'as_vydubichi') return 6
                if (this.$route.params.name == 'uaf') return 7
                if (this.$route.params.name == 'upl') return 8
                if (this.$route.params.name == 'president-hotel') return 9
            }
            return this.$route.params.id
        },

        casesLength() {
            switch (this.customerId) {
                case 1:
                    return 2
                break;
                case 2:
                    return 1
                break;
                case 3:
                    return 1
                break;
                case 4:
                    return 2
                break;
                case 5:
                    return 3
                break;
                case 6:
                    return 1
                break;
                case 7:
                    return 2
                break;
                case 8:
                    return 2
                break;
                case 9:
                    return 1
                break;
            }
        },

        isPrevServiceButtonVisible() {
            return !!this.getServiceNameById(this.$route.params.name, this.itemId - 1);
        },

        isNextServiceButtonVisible() {
            return !!this.getServiceNameById(this.$route.params.name, this.itemId + 1);
        }
    },

    watch: {
        customerId(v) {
            this.getData(v, 0);
        },
    },

    mounted() {
        console.log(this.itemId);
        this.getData(this.customerId, 0);
        this.itemId = this.getIdByName(this.customerId)

        setTimeout(() => {
            this.preloader = false
        }, 700);

        if (!this.isMobile) {
            this.$refs.navigation.$refs.header__logo.style.opacity = 1
            this.$refs.navigation.$refs.header__logo.style.height = '31px'
        }

        
    },

    methods: {
        getData(customerId, customerItemId) {
            if (typeof customerId == 'string') {
                customerId = +customerId
            }

            this.title = this.$t('pages.one-customer.customer-main-title')[customerId-1]
            window.document.title = this.title + ' | STERKONIX'
        },

        getIdByName(customerId) {

            switch (customerId) {
                case 1:
                    if (this.$route.params.serviceName == 'es_platform') {
                        return 1
                    } else  {
                        return 2
                    }
                break;
                case 3:
                    return 1
                break;
                case 4:
                    if (this.$route.params.serviceName === 'kyiv_infoscreens') {
                        return 1
                    } else {
                        return 2
                    }
                    
                break;
                case 5:
                    if (this.$route.params.serviceName === 'traffic_system') {
                        return 1
                    } else if (this.$route.params.serviceName === 'website') {
                        return 2
                    } else if (this.$route.params.serviceName === 'brand_book') {
                        return 3
                    }
                
                break;
                case 6:
                    return 1
                break;
                case 7:
                    if (this.$route.params.serviceName === 'reports_system') {
                        return 1
                    } else {
                        return 2
                    }
                break;
                case 8:
                    if (this.$route.params.serviceName === 'website') {
                        return 1
                    } else {
                        return 2
                    }
                break;
                case 9:
                    return 1
                break;
            }
        },

        getServiceNameById(customerName, id) {
            switch (customerName) {
                case 'es':
                    if (id === 1) {
                        return 'es_platform'
                    } else if (id === 2) {
                        return 'eCast'
                    }
                break;
                case 'overprint':
                    if (id === 1) {
                        return 'online_calculator'
                    }
                break;
                case 'kyivcitycouncil':
                    if (id === 1) {
                        return 'kyiv_infoscreens'
                    } else if (id === 2) {
                        return 'LMS'
                    }
                break;
                case 'kpt':
                    if (id === 1) {
                        return 'traffic_system'
                    } else if (id === 2) {
                        return 'website'
                    } else if (id === 3) {
                        return 'brand_book'
                }
                break;
                case 'as_vydubichi':
                    if (id === 1) {
                        return 'bus_ticket_system'
                    }
                break;
                case 'uaf':
                    if (id === 1) {
                        return 'reports_system'
                    } else if (id === 2) {
                        return 'UAF_infoscreens'
                    }
                break;
                case 'upl':
                    if (id === 1) {
                        return 'website'
                    } else if (id === 2) {
                        return 'UPL_system'
                    }
                break;
                case 'president-hotel':
                    if (id === 1) {
                        return 'information_screens'
                    }
                break;
            }
        },

        itemClick(id) {
            let serviceName = this.getServiceNameById(this.$route.params.name, id);

            this.$router.replace({ name: 'Customer', params: { id: this.$route.id, name: this.$route.params.name, item: id, serviceName } }).then(()=> {
                window.document.title = this.title + ' | STERKONIX';
                this.itemId = this.$route.params?.item
            }).catch(() => {})
        },

        setPreviousService() {
            let serviceName = this.getServiceNameById(this.$route.params.name, this.itemId - 1);
            if (serviceName) {
                 this.$router.replace({ name: 'Customer', params: { name: this.$route.params.name, item: this.itemId - 1, serviceName } }).then(()=> {
                    window.document.title = this.title + ' | STERKONIX';
                    this.itemId = this.itemId - 1
                }).catch(() => {})
            }
        },

        setNextService() {
            let serviceName = this.getServiceNameById(this.$route.params.name, this.itemId + 1);
            if (serviceName) {
                 this.$router.replace({ name: 'Customer', params: { name: this.$route.params.name, item: this.itemId + 1, serviceName } }).then(()=> {
                    window.document.title = this.title + ' | STERKONIX';
                    this.itemId = this.itemId + 1
                }).catch(() => {})
            }
        },

        changeLocale() {
            setTimeout(() => {
                this.getData(this.$route.params.id)
            }, 100);
        },

        fallbackServiceId() {
            this.itemId = 1
        }
    },

   
};
</script>

<style lang="scss" scoped>

.one-customer {

    @media screen and (max-width: $lg) {
        .typography {
            color: $color_black !important
        }

        ul.base > li {
            color: $color_black !important
        }
    }

    .section__content {
        height: 100vh;

        @media screen and (max-width: $lg) {
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    .section__title {
        position: relative;
        font-size: 40px !important;

        @media screen and (max-width: $lg) {
            span {
                color: white;
            }
        }

        @media screen and (max-width: $lg) {
            padding-top: 140px;
            position: absolute;
        }
    }

    .section__right {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: $lg) {
            width: 100vw;
            position: absolute;
            margin-top: 500px;
            background-color: white;
        }
    }

    &__buttons {
        display: flex;
        margin-top: 40px;
        margin-bottom: 55px;
    }

    &__button {
        &:first-child {
            margin-right: 10px;
        }
    }

/*     &__bookmarks {
        background-color: #3438397d;
        padding: 11px 15px;
        color: white;
        border-radius: 4px;
        display: flex;
        align-items: center;
        z-index: 222;
        position: absolute;
        width: fit-content;
        font-size: 12px;

        @media screen and (max-width: $lg) {
            top: 90px;
        }

        img {
            margin-right: 10px;
            height: 20px;
        }
    } */

    

    .section__left {
        @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 900px) {
            padding-top: 70px;
            padding-bottom: 70px;
        }

        @media screen and (max-width: $lg) {
            background-color: white;
        }
    }

    &__content {
        margin: 120px 12vw 40px 4vw;
        margin-right: 0;
        padding-right: 4vw;
        overflow: auto;

        @media screen and (max-width: $lg) {
            margin: 40px 0 40px 20px;
        }
    }

    &__itemLogo {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: 20px;
        height: 80px;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $lg) {
            top: -40px;
            transform: none;
            left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            max-width: 100%;
            max-height: 80px;

            @media screen and (max-width: $lg) {
                margin-right: auto;
            }
        }
    }

    &__ripples {
        height: 100vh;
        width: 50vw;
        position: fixed;
        left: 0;
        top: 0;
        background-position: center;
        background-size: 100%;
        transition: .3s;
        background-color: $color_black;

        @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 900px) {
            height: 100vh;
        }

        @media screen and (max-width: $lg) {
            height: 430px;
            width: 100vw;
            position: absolute;
        }

        &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: #2a2b36a8;
            transition: .2s;
            left: 0;
            top: 0;
        }
    }

    &__item {
        h4 {
            margin-top: 40px;
            color: $color_red;
        }
    }
}

.one-customer-cases {
    display: flex;
    flex-direction: column;
    padding-right: 4vw;
    position: relative;
    padding-top: 100px;

    @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: $lg) and (min-height: 595px) {
        padding-top: 80px;
    }

    &__item {
        padding: 50px 40px;
        display: flex;
        align-items: flex-start;
        background-color: #1e202bde;
        border-bottom: 1px solid $color_red;
        cursor: pointer;
        transition: .3s;

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: $lg) and (min-height: 595px) {
            padding: 30px 20px;
        }

        &:hover {
            background-color: #8b0f22c4;
        }

        &--active {
            background-color: #8b0f22c4;
        }

        & + & {
            margin-top: 20px;
        }
    }

    &__title {
        font-size: 26px;
        font-weight: bold;
        letter-spacing: 1.63px;
        color: white;
        margin: 0;

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: $lg) and (min-height: 595px) {
            font-size: 16px;
        }
    }
}
</style>
