<template>
    <div>
        <transition name="fade">
            <Preloader v-if="preloader" />
        </transition>

        <Navigation ref="navigation" @changeLocale="changeLocale"/>
        <full-page v-if="isActiveFullpage" ref="fullpage" :options="fullpageOptions" id="fullpage" :key="remountKey">
            <MainSection ref="mainSection" @goDown="goDown"/>
            <AboutSection ref="aboutSection" @goContacts="goContacts"/>
            <HowWorkingSection ref="howWorkingSection" />
            <ServicesSection ref="servicesSection"/>
            <SolutionsSection ref="solutionsSection" />
            <VacanciesSection ref="vacanciesSection" />
            <CustomersSection ref="customersSection" />
            <ContactsSection ref="contactsSection"/>
            <FooterSection ref="footerSection" />
        </full-page>
        <div v-else>
            <MainSection id="main" ref="mainSection" @goDown="goDown"/>
            <AboutSection id="about" ref="aboutSection" @goContacts="goContacts"/>
            <HowWorkingSection id="how" ref="howWorkingSection" />
            <ServicesSection id="services" ref="servicesSection"/>
            <SolutionsSection id="solutions" ref="solutionsSection" />
            <VacanciesSection id="vacancies" ref="vacanciesSection" />
            <CustomersSection id="customers" ref="customersSection" />
            <ContactsSection id="contacts" ref="contactsSection" />
            <FooterSection id="footer" ref="footerSection" />
        </div>
        <div class="fullpage-numbers" v-if="isActiveFullpage">
            <span @click="setFirstSlide" class="typography fullpage-numbers__point fullpage-numbers__point--main">01</span>
            <span @click="setLastSlide" class="typography fullpage-numbers__point fullpage-numbers__point--last">09</span>
        </div>
    </div>
</template>

<script>

import MainSection from "@/components/sections/MainSection"
import HowWorkingSection from "@/components/sections/HowWorkingSection"
import SolutionsSection from "@/components/sections/SolutionsSection"
import AboutSection from "@/components/sections/AboutSection"
import ServicesSection from "@/components/sections/ServicesSection"
import VacanciesSection from "@/components/sections/VacanciesSection"
import ContactsSection from "@/components/sections/ContactsSection"
import CustomersSection from "@/components/sections/CustomersSection"
import FooterSection from "@/components/sections/FooterSection"

import Preloader from "@/components/Preloader"
import Navigation from "@/components/Navigation"

export default {
    components: {
        Navigation,
        MainSection,
        HowWorkingSection,
        SolutionsSection,
        AboutSection,
        ServicesSection,
        VacanciesSection,
        ContactsSection,
        CustomersSection,
        FooterSection,
        Preloader
    },
    data() {
        return {
            remountKey: 0,
            isActiveFullpage: true,
            preloader: true,
            fullpageOptions: {
                licenseKey: 'i getted a license',
                menu: '#menu',
                anchors: ['main', 'about', 'how', 'services', 'solutions', 'vacancies', 'customers', 'contacts', 'footer'],
                navigation: true,
                lazyLoading: true,
                slidesNavigation: true,
                navigationPosition: 'left',
                showActiveTooltip: true,
                onLeave: (origin, destination, direction) => {

                    /* lazy load */
                    destination.item.classList.add('section--loaded');
                    setTimeout(() => {
                        origin.item.classList.remove('section--loaded');
                    }, 600);

                    let slideIndex = destination.index + 1

                    if (slideIndex == 1) {
                        this.$refs.mainSection.$refs.main__video.play()
                        this.$refs.navigation.$refs.header__logo.style.opacity = 0
                        this.$refs.navigation.$refs.header__logo.style.height = 0
                    }

                    if (slideIndex == 2) {
                        this.$refs.aboutSection.$refs.about__video.play()
                    }

                    if (slideIndex == 3) {
                        this.$refs.howWorkingSection.$refs.howWorking__video.play()
                    }

                    if (slideIndex == 4) {
                        this.$refs.servicesSection.$refs.services__video.play()
                    }

                    if (slideIndex == 5) {
                        this.activeRippleEffect()                       
                    }                    

                    if (slideIndex == 6) {
                        this.$refs.vacanciesSection.$refs.vacancies__video.play()
                    }

                    if (slideIndex == 7) {
                        this.$refs.customersSection.$refs.customers__video.play()
                    }

                    if (slideIndex == 8) {
                        this.$refs.contactsSection.$refs.contacts__video.play()
                        /* $('.fake-widget').trigger('click') */
                       // window.Intercom('show');
                    }

                    if (origin.index + 1 == 8) {
                     //   window.Intercom('hide');
                    }

                    if (slideIndex == 9) {
                        this.$refs.footerSection.$refs.footer__video.play()
                    }

                    if (slideIndex >= 2) {
                        this.$refs.navigation.$refs.header__logo.style.opacity = 1
                        this.$refs.navigation.$refs.header__logo.style.height = '31px'
                    }
                },
            },
        }
    },

    mounted() {
        let body = document.getElementsByTagName('body')[0];

        setTimeout(() => {
            this.preloader = false
        }, 650);

        if (body.clientWidth <= 1199) {
            this.isActiveFullpage = false
            this.$refs.fullpage.api.destroy();
            
            setTimeout(() => {
                this.activeRippleEffect() 
            }, 200);
        }

        if (this.isActiveFullpage) {
            if (body.clientHeight < 600) {
                this.$refs.fullpage.api.destroy();
                setTimeout(() => {
                    this.activeRippleEffect() 
                }, 200);
            }
        }
        
        window.matchMedia(`(max-width: 1199px)`).addEventListener('change', this.screenTest)

        console.clear()
    },

    methods: {
        screenTest(e) {
            if (e.matches) {
                this.isActiveFullpage = false
                this.$refs.fullpage.api.destroy();
            }
        },
        activeRippleEffect() {
           /*  if ($('.solutions__ripples canvas').length == 0) {
                $('.solutions__ripples').ripples({
                    perturbance: 0.04
                });
            }  */
        },
        goDown(v) {
            if (v) {
                this.$refs.fullpage.api.moveSectionDown()
            }
        },
        goContacts() {
            if (this.$refs.fullpage?.api) {
                this.$refs.fullpage.api.moveTo('contacts');
            }
            else {
                const contacts = document.getElementsByClassName('contacts')[0]
                contacts.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            

        },
        setFirstSlide() {
            this.$refs.fullpage.api.silentMoveTo('main');
        },
        setLastSlide() {
            this.$refs.fullpage.api.silentMoveTo('footer');
        },

        changeLocale() {
            setTimeout(() => {
                this.remountKey += 1;
            }, 100);

        }
    },

    beforeDestroy() {
        if (this.$refs.fullpage) {
            this.$refs.fullpage.api.destroy('all'); 
        }
        
        window.matchMedia(`(max-width: 1199px)`).removeEventListener('change', this.screenTest)
    }
};
</script>

<style lang="scss" scoped>

    .fullpage-numbers {
        position: fixed;
        left: 46px;
        top: 50%;
        transform: translate(0, -50%);
        display: flex;
        flex-direction: column;

        &__point {
            font-size: 16px;
            letter-spacing: 1.74px;
            position: relative;
            color: rgb(235, 235, 235);
            display: flex;
            font-weight: 300;
            justify-content: center;
            cursor: pointer;
            transition: .2s;

            &:hover {
                color: $color_red;
            }

            &--main {
                top: -130px;
            }

            &--last {
                bottom: -130px;
            }
        }
    }
    
</style>


