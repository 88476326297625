<template>
    <button
        v-if="!Object.keys(to || {}).length"
        class="button"
        :type="type"
        :disabled="disabled"
        :class="[`button--${theme}`]"
        @click="$emit('click')"
    >
        <span v-if="caption"> {{ caption }}</span>
    </button>
    <a :href="to"
        v-else-if="typeof to == 'string'"
        class="btn button"
        :class="[`button--${theme}`]"
        target="_blank"
    >
        <img v-if="icon" class="button__icon" :src="require(`@/${icon}`)" alt="icon">
        <span v-if="caption"> {{ caption }}</span>
    </a>
    <localized-link
        v-else
        :to="to"
        :disabled="disabled"
        class="btn button"
        :class="[`button--${theme}`]"
    >
        <span v-if="caption"> {{ caption }}</span>
    </localized-link>
</template>

<script>
export default {
    name: "ButtonComponent",
    props: {
        caption: {
            required: false,
            type: String
        },
        to: {
            required: false,
            type: [Object, String]
        },
        theme: {
            required: false,
            type: String,
            default: "primary" // primary
        },
        type: {
            required: false,
            type: String,
            default: "button" // submit
        },
        disabled: {
            required: false,
            type: Boolean
        },
        icon: {
            required: false,
            type: String,
            default: ''
        }
    },

}
</script>

<style lang="scss" scoped>
    .button {
        border: none;
        border-radius: 2px;
        color: white;
        text-align: center;
        font-family: $ff-primary;
        letter-spacing: 2px;
        font-size: 20px;
        height: max-content;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .2s;
        font-weight: 400;
        padding: 17px 20px;
        width: 240px;
        max-width: 100%;
        text-align: center;

        &:hover {
            color: white;
            box-shadow: 0 8px 12px -6px rgba(255, 25, 61, 0.4);
        }

        &:focus {
            outline: none;
        }


        &--primary {
            background-color: $color_red;
            border: solid 1px $color_red;
        }

        @media screen and (max-width: $lg) {
            width: 210px;
            padding: 12px 18px;
            font-size: 16px;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            width: 210px;
            padding: 12px 18px;
            font-size: 16px;
        }

        &__icon {
            filter: invert(1);
            margin-right: 15px;
        }
    }

    [disabled] {
        cursor: default;
        background-color: $color_gray;
        border-color: $color_gray;
        color: white;
    }

</style>