<template>
    <div class="section footer">
        <div class="section__content">
            <div class="section__left">
                 <video ref="footer__video" autoplay muted loop playsinline class="section__leftVideo">
                  <source src="@/assets/video/glitch1.mp4" type="video/mp4">
                </video>

                <div class="footer__figure"></div>

                <div class="footer__menu">
                    <localized-link to="/#main" @click.native="toggleMenu" class="footer__link">{{ $t('navigation.main') }}</localized-link>
                    <localized-link to="/#about" @click.native="toggleMenu" class="footer__link">{{ $t('navigation.about 3k') }}</localized-link>
                    <localized-link to="/#services" @click.native="toggleMenu" class="footer__link">{{ $t('navigation.services') }}</localized-link>
                    <localized-link to="/#solutions" @click.native="toggleMenu" class="footer__link">{{ $t('navigation.solutions') }}</localized-link>
                    <localized-link to="/#vacancies" @click.native="toggleMenu" class="footer__link">{{ $t('navigation.vacancies') }}</localized-link>
                    <localized-link to="/#customers" @click.native="toggleMenu" class="footer__link">{{ $t('navigation.customers') }}</localized-link>
                    <localized-link to="/#contacts" @click.native="toggleMenu" class="footer__link">{{ $t('navigation.contacts') }}</localized-link>

                    <div v-if="isMobile" class="footer__menuMobile">
                        <div class="footer__solutions">
                            <!-- <h4 class="typography typography--h4">{{ $t('sections.footer.Solutions:') }}</h4> -->
                            <localized-link :to="{ name: 'Solution', params: { id: 1, name: 'patentem' } }" class="footer__serviceCaption">Patentem</localized-link>
                            <localized-link :to="{ name: 'Solution', params: { id: 2, name: 'elect-pro' } }" class="footer__serviceCaption">ElectPro</localized-link>
                            <localized-link :to="{ name: 'Solution', params: { id: 3, name: 'scientia' } }" class="footer__serviceCaption">Scientia</localized-link>
                            <localized-link :to="{ name: 'Solution', params: { id: 4, name: 'bus-station' } }" class="footer__serviceCaption">Bus station<br> management system</localized-link>
                            <localized-link :to="{ name: 'Solution', params: { id: 5, name: 'digitalization-of-politics' } }" class="footer__serviceCaption">{{ this.$t('sections.solutions.Solution5') }}</localized-link>
                        </div>
                    </div>
                </div>

                <div class="footer__rights">
                    © 2022 All rights reserved
                </div>
            </div>
            <div class="section__right">
                <div class="footer__services" style="display: none">
                    <h4 class="typography typography--h4">{{ $t('sections.footer.Services:') }}</h4>
                    <p class="typography footer__serviceCaption">{{ $t('navigation.Business Analysis & Consulting') }}</p>
                    <p class="typography footer__serviceCaption">{{ $t('navigation.Digital Transformation') }}</p>
                    <p class="typography footer__serviceCaption">{{ $t('navigation.Solutions Integration') }}</p>
                    <p class="typography footer__serviceCaption">{{ $t('navigation.Custom Software Development') }}</p>
                    <p class="typography footer__serviceCaption">{{ $t('navigation.Mobile Solutions') }}</p>
                    <p class="typography footer__serviceCaption">{{ $t('navigation.Software Design, Architecture, and Prototyping') }}</p>
                </div>
                <div class="footer__solutions">
                    <!-- <h4 class="typography typography--h4">{{ $t('sections.footer.Solutions:') }}</h4> -->
                    <localized-link :to="{ name: 'Solution', params: { id: 1, name: 'patentem' } }" class="footer__serviceCaption">Patentem</localized-link>
                    <localized-link :to="{ name: 'Solution', params: { id: 2, name: 'elect-pro' } }" class="footer__serviceCaption">ElectPro</localized-link>
                    <localized-link :to="{ name: 'Solution', params: { id: 3, name: 'scientia' } }" class="footer__serviceCaption">Scientia</localized-link>
                    <localized-link :to="{ name: 'Solution', params: { id: 4, name: 'bus-station' } }" class="footer__serviceCaption">Bus station<br> management system</localized-link>
                    <localized-link :to="{ name: 'Solution', params: { id: 5, name: 'digitalization-of-politics' } }" class="footer__serviceCaption">{{ this.$t('sections.solutions.Solution5') }}</localized-link>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {

        }
    },

    methods: {
        toggleMenu(e) {
            if (e.target.classList.contains('footer__link') && this.isMobile) {
                let id = e.target.attributes.href.value.substring(2);

                document.querySelector(`#${id}`).scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        }
    }

};
</script>

<style lang="scss" scoped>
.footer {
    overflow: hidden;

    .section__content {
        @media screen and (max-height: 759px) and (min-width: $xl) {
            min-height: 900px;
        } 

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            min-height: auto;
        } 
    }

    .section__right {
        display: flex;
        flex-direction: column;
        padding-bottom: 50px;
        padding-top: 27vh;

        h4 {
            margin-bottom: 40px;

             @media screen and (max-width: $xxl) {
                 margin-bottom: 24px;
            }
        }

        @media screen and (min-width: $lg) and (max-width: $xl) {
            padding-top: 0;
            justify-content: center;
            padding-bottom: 100px;
        }
        
        @media screen and (max-width: $lg) {
            height: 0;
            padding-bottom: 0;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            padding-top: 20vh;
        }
    }

    .section__left {
        padding-top: 27vh;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $lg) and (max-width: $xl) {
            padding-top: 0;
            padding-bottom: 100px;
            justify-content: center;
        }

        @media screen and (max-width: $lg) {
            padding-bottom: 100px;
            padding-top: 0;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            padding-top: 20vh;
        }
    }


    &__figure {

        .section--loaded & {
            background-image: url("~@/assets/img/footer_figure.png");
            opacity: .6;
        }

        background-position: center;
        background-size: contain;
        opacity: 0;
        height: 70vh;
        width: 70vw;
        left: -30vw;
        bottom: -40vh;
        z-index: 2;
        position: absolute;
        background-repeat: no-repeat;

        @media screen and (max-width: $xxl) {
            height: 500px;
            width: 500px;
            left: -18vw;
            transform: rotate(40deg);
            top: 72vh;
        }

    }

    &__menu {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 10;

        @media screen and (max-width: $lg) {
            align-items: center;
        }
    }

    &__menuMobile {
        .footer__solutions {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            a {
                color: white;
            }
        }
    }

    &__serviceCaption {
        margin-bottom: 20px;
        font-weight: 500;

        letter-spacing: 0.77px;
        font-size: 28px;
        margin-bottom: 20px;
        text-transform: lowercase;

        @media screen and (max-width: $lg) {
            font-size: 24px;
        }

        &:last-child {
            margin-bottom: 0;
        }
        
        .footer__solutions & {
            color: $color_black;

            &:hover {
                color: $color_red;
            }
        }
    }

    &__solutions {
        display: flex;
        flex-direction: column;
    }

    &__link {
        color: white;
        letter-spacing: 0.77px;
        font-size: 28px;
        margin-bottom: 20px;
        text-transform: lowercase;

        @media screen and (max-width: $lg) {
            font-size: 24px;
        } 

        &:hover, &:focus {
            color: $color_red;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__rights {
        position: absolute;
        bottom: 30px;
        left: 40px;
        font-family: $ff-primary;
        font-size: 12px;
        letter-spacing: 0.75px;
        color: #c2c2c2;

        @media screen and (max-width: $lg) {
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

}
</style>
