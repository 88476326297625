import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from 'vue-lang-router';
import axios from 'axios'
import VueAxios from 'vue-axios'

/* Fullpage js */
import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
import 'fullpage.js/dist/fullpage.css'
import VueFullPage from 'vue-fullpage.js'

/* VueTelInput */
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

/* Base style */
import '@/assets/scss/app.scss'

/* Base components */
import ButtonComponent from "@/components/ButtonComponent"
Vue.component('ButtonComponent', ButtonComponent)

/* For ripples effect */
window.$ = window.jQuery = require('jquery');

/* Mixin */
Vue.mixin({
  computed: {
    isMobile() {
        return this.$store.getters.getResolution === 'mobile'
    }
  },
})

/* Vue use */
Vue.use(VueAxios, axios)
Vue.use(VueFullPage);
Vue.use(VueTelInput, {defaultCountry: 'UA'});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
