<template>
    <div>
        <Navigation ref="navigation" @changeLocale="changeLocale" />
        <div class="section one-service">
            <div class="section__content">
                <div class="section__left">
                    <h2 class="typography typography--h2 section__title">
                        <span class="red">{{ title }}</span>
                    </h2>
                    <video
                        ref="one-service__video"
                        autoplay
                        muted
                        loop
                        playsinline
                        class="section__leftVideo"
                    >
                        <source
                            src="@/assets/video/glitch1.mp4"
                            type="video/mp4"
                        />
                    </video>

                    <div :style="'background-image: url('+ serviceImg + ')'" class="one-service__mainCircle" :class="{'one-service__mainCircle--hidden' : !stepHidden}"></div>
                    <div :style="'background-image: url(' + require('@/assets/img/square_circle.svg') + ')'" class="one-service__squareCircle" :class="{'one-service__mainCircle--new' : stepNew}"></div>
                    <div :style="'opacity:0; background-image: url(' + serviceImg + ')'" class="one-service__squareCircle" :class="{'one-service__squareCircle--new' : !stepNew}"></div>

                    <div class="one-service__squareCircle"></div>
                </div>
                <div class="section__right">
                    <div v-if="serviceName == 'consulting'" class="consulting">
                        <h4 class="typography typography--h4">Lorem ipsum consulting</h4>
                        <p class="typography typography--paragraph">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat.
                        </p>
                        <p class="typography typography--paragraph">
                            Duis aute irure dolor in reprehenderit in voluptate
                            velit esse cillum dolore eu fugiat nulla pariatur.
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est
                            laborum.Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                        </p>
                    </div>
                    <div v-if="serviceName == 'development'" class="development">
                        <h4 class="typography typography--h4">Lorem ipsum development</h4>
                        <p class="typography typography--paragraph">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat.
                        </p>
                        <p class="typography typography--paragraph">
                            Duis aute irure dolor in reprehenderit in voluptate
                            velit esse cillum dolore eu fugiat nulla pariatur.
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est
                            laborum.Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.

                            Duis aute irure dolor in reprehenderit in voluptate
                            velit esse cillum dolore eu fugiat nulla pariatur.
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est
                            laborum.Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                            Duis aute irure dolor in reprehenderit in voluptate
                            velit esse cillum dolore eu fugiat nulla pariatur.
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est
                            laborum.Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                        </p>
                    </div>
                    <div v-if="serviceName == 'business'" class="business">
                        <h4 class="typography typography--h4">Lorem ipsum business</h4>
                        <p class="typography typography--paragraph">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat.
                        </p>
                        <p class="typography typography--paragraph">
                            Duis aute irure dolor in reprehenderit in voluptate
                            velit esse cillum dolore eu fugiat nulla pariatur.
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est
                            laborum.Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                        </p>
                    </div>
                    <div v-if="serviceName == 'integration'" class="integration">
                        <h4 class="typography typography--h4">Lorem ipsum integration</h4>
                        <p class="typography typography--paragraph">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat.
                        </p>
                        <p class="typography typography--paragraph">
                            Duis aute irure dolor in reprehenderit in voluptate
                            velit esse cillum dolore eu fugiat nulla pariatur.
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est
                            laborum.Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                        </p>
                    </div>
                    <div v-if="serviceName == 'audit'" class="audit">
                        <h4 class="typography typography--h4">Lorem ipsum audit</h4>
                        <p class="typography typography--paragraph">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat.
                        </p>
                        <p class="typography typography--paragraph">
                            Duis aute irure dolor in reprehenderit in voluptate
                            velit esse cillum dolore eu fugiat nulla pariatur.
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est
                            laborum.Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                        </p>
                    </div>
                    <div v-if="serviceName == 'apps'" class="apps">
                        <h4 class="typography typography--h4">Lorem ipsum apps</h4>
                        <p class="typography typography--paragraph">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat.
                        </p>
                        <p class="typography typography--paragraph">
                            Duis aute irure dolor in reprehenderit in voluptate
                            velit esse cillum dolore eu fugiat nulla pariatur.
                            Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est
                            laborum.Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                        </p>
                    </div>
                </div>

                <div class="one-service__tabs">
                    <localized-link 
                        class="one-service__tabItem" 
                        :class="{ 'one-service__tabItem--active' : this.$route.params.name == 'consulting'}" 
                        :to="{ name: 'Service', params: { name: 'consulting' } }">
                        {{ $t('pages.service.Consulting') }}
                    </localized-link>
                    <localized-link 
                        class="one-service__tabItem" 
                        :class="{ 'one-service__tabItem--active' : this.$route.params.name == 'development' }" 
                        :to="{ name: 'Service', params: { name: 'development' } }">
                        {{ $t('pages.service.Development') }}
                    </localized-link>
                    <localized-link 
                        class="one-service__tabItem" 
                        :class="{ 'one-service__tabItem--active' : this.$route.params.name == 'business' }" 
                        :to="{ name: 'Service', params: { name: 'business' } }">
                        {{ $t('pages.service.Business analysis') }}
                    </localized-link>
                    <localized-link 
                        class="one-service__tabItem" 
                        :class="{ 'one-service__tabItem--active' : this.$route.params.name == 'integration' }" 
                        :to="{ name: 'Service', params: { name: 'integration' } }">
                        {{ $t('pages.service.Integrations') }}
                    </localized-link>
                    <localized-link 
                        class="one-service__tabItem" 
                        :class="{ 'one-service__tabItem--active' : this.$route.params.name == 'audit' }" 
                        :to="{ name: 'Service', params: { name: 'audit' } }">
                        {{ $t('pages.service.Audit') }}
                    </localized-link>
                    <localized-link 
                        class="one-service__tabItem" 
                        :class="{ 'one-service__tabItem--active' : this.$route.params.name == 'apps' }" 
                        :to="{ name: 'Service', params: { name: 'apps' } }">
                        {{ $t('pages.service.Mobile apps') }}
                    </localized-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navigation from "@/components/Navigation";

export default {
    name: "Service",
    components: {
        Navigation
    },
    data() {
        return {
            title: "",
            stepHidden: true,
            stepNew: true
        };
    },

    computed: {
        serviceName() {
            return this.$route.params.name || "consulting"
        },
        serviceImg() {
            try {
                return require(`@/assets/img/services/${this.serviceName}.png`)
            } catch (error) {
                return require(`@/assets/img/services/diz.png`)
            }
        }
    },

    watch: {
        serviceName(v) {
            this.getData(v);
            this.changeImage();
        }
    },

    methods: {
        getData(n) {
            switch (n) {
                case "consulting":
                    this.title = this.$t('pages.service.Consulting');
                    break;
                case "development":
                    this.title = this.$t('pages.service.Development');
                    break;
                case "business":
                    this.title = this.$t('pages.service.Business analysis');
                    break;
                case "integration":
                    this.title = this.$t('pages.service.Integrations');
                    break;
                case "audit":
                    this.title = this.$t('pages.service.Audit');
                    break;
                case "apps":
                    this.title = this.$t('pages.service.Mobile apps')
                    break;
            }

            window.document.title = this.title + ' | STERKONIX'
        },
        changeImage() {
            this.stepHidden = false;
            this.stepNew = false;

            setTimeout(() => {
                this.stepHidden = true;
                this.stepNew = true;
            }, 990);
        },

        changeLocale() {
            setTimeout(() => {
                this.getData(this.$route.params.name)
            }, 100);
        }
    },

    mounted() {
        this.getData(this.serviceName);
    }
};
</script>

<style lang="scss" scoped>

.one-service {

    .section__content {
        @media screen and (max-width: $lg) {
            flex-direction: column;
        }
    }

    .section__title {
        font-size: 80px;

        @media screen and (max-width: $xl) {
            position: absolute;
        }

        @media screen and (max-width: $lg) {
            position: relative;
        }
    }

    .section__right {
        min-height: 100vh;
        padding-top: 400px;
        padding-bottom: 80px;

        @media screen and (max-width: $xl) {
            padding-top: 150px;
            padding-top: 300px;
        }

        @media screen and (max-width: $lg) {
            position: relative;
            width: 100vw;
            padding: 20px;
            min-height: 70vh;
            padding-bottom: 60px;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            padding-top: 280px;
        } 
    }

    .section__left {
        @media screen and (max-width: $lg) {
            padding-top: 360px;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {

            padding-top: 90px;
        } 
    }

    &__tabs {
        position: absolute;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 310px;
        z-index: 4;
        padding: 0 12vw;

        @media screen and (max-width: $xl) {
            padding: 0 calc(12vw - 20px);
        }

        @media screen and (max-width: $lg) {
            margin: 0;
            padding-left: 0;
            padding-top: 80px;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            margin-top: 180px;
        }
    }

    &__tabItem {
        color: $color_black;
        padding: 12px 20px;
        background-color: #e7ebed;
        border-bottom: 1px solid $color_red;

        &--active, &:hover {
            background-color: $color_red;
            color: white;
        }

        & + & {
            margin-left: 5px;
        }

        @media screen and (max-width: $xl) {
            flex-basis: 50%;
            color: white;
            padding: 12px 20px;
            background-color: transparent;
            border-bottom: none;

            & + & {
                margin-left: 0;
            }

            &--active, &:hover {
                background-color: transparent;
                color: $color_red;
            }
        }

        @media screen and (max-width: $lg) {
            flex-basis: 100%;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            font-size: 14px;
        }
    }

    &__squareCircle {
        background-image: url("~@/assets/img/square_circle.svg");
        background-position: center;
        background-size: cover;
        height: 380px;
        width: 380px;
        left: calc(50vw - 190px);
        bottom: 70px;
        z-index: 1;
        position: fixed;
        background-repeat: no-repeat;

        &--new {
            transition: 1s;
            z-index: 2;
            opacity: 1 !important;
            left: calc(50vw - 700px);
        }

        @media screen and (min-width: $fullhd + 1px) {
            bottom: 25vh;
        }

        @media screen and (max-width: 1500px) {
            &--new {
                left: calc(100vw - 90px);
            }
        }

        @media screen and (max-width: $xl) {
            display: none;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            height: 250px;
            width: 250px;
            left: calc(50vw - 90px);

            &--new {
                left: 12vw;
            }
        } 
    }

    &__mainCircle {
        background-position: center;
        background-size: cover;
        height: 380px;
        width: 380px;
        left: calc(50vw - 700px);
        bottom: 70px;
        z-index: 2;
        position: fixed;
        background-repeat: no-repeat;
        transition: none;

        @media screen and (min-width: $fullhd + 1px) {
            bottom: 25vh;
        }

        @media screen and (max-width: 1500px) {
            left: 12vw
        }

        @media screen and (max-width: $xl) {
            display: none;
        }

         @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            height: 250px;
            width: 250px;
        } 

        &--hidden {
            left: -51vw;
            transition: 1s;
        }
    }
}
</style>
