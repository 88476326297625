<template>
    <div class="section services">
        <div class="section__content">
            <div class="section__left">
                <h2 class="typography typography--h2 section__title">
                    <span class="white">{{ $t('sections.services.Our') }} </span>
                    <span class="red">{{ $t('sections.services.services') }}</span>
                </h2>
                <video ref="services__video" autoplay muted loop playsinline class="section__leftVideo">
                  <source src="@/assets/video/glitch1.mp4" type="video/mp4">
                </video>
            </div>
            <div class="section__right"></div>
            <div class="services__list">
                <div class="services__item services__item--white">
                    <h4 class="typography typography--h4 services__link">{{ $t('navigation.Business Analysis & Consulting') }}</h4>
                    <p class="typography typography--paragraph services__itemText">{{ $t('sections.services.1Description') }}</p>
                </div>
                <div class="services__item">
                    <h4 class="typography typography--h4 services__link">{{ $t('navigation.Digital Transformation') }}</h4>
                    <p class="typography typography--paragraph services__itemText">{{ $t('sections.services.2Description') }}</p>
                </div>
                <div class="services__item services__item--white">
                    <h4 class="typography typography--h4 services__link">{{ $t('navigation.Solutions Integration') }}</h4>
                    <p class="typography typography--paragraph services__itemText">{{ $t('sections.services.3Description') }}</p>
                </div>
                <div class="services__item">
                    <h4 class="typography typography--h4 services__link">{{ $t('navigation.Custom Software Development') }}</h4>
                    <p class="typography typography--paragraph services__itemText">{{ $t('sections.services.4Description') }}</p>
                </div>
                <div class="services__item services__item--white">
                    <h4 class="typography typography--h4 services__link">{{ $t('navigation.Mobile Solutions') }}</h4>
                    <p class="typography typography--paragraph services__itemText">{{ $t('sections.services.5Description') }}</p>
                </div>
                <div class="services__item">
                    <h4 class="typography typography--h4 services__link">{{ $t('navigation.Software Design, Architecture, and Prototyping') }}</h4>
                    <p class="typography typography--paragraph services__itemText">{{ $t('sections.services.6Description') }}</p>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.services {

    .section__left {
        
        @media screen and (max-width: $fullhd) {
            padding-top: 100px;
        }

        @media screen and (max-height: 759px) and (min-width: $xl) {
            padding-top: 130px;
        } 

        @media screen and (max-width: $xl) {
            padding-bottom: 700px;
        }

        @media screen and (max-width: $lg) {
            padding-bottom: 0;
        }

        @media screen and (max-width: $sm) {
            padding-top: 50px;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            padding-top: 12vh;
        }

        @media screen and (min-width: $lg) and (max-width: $xl) {
            padding-top: 0;
        }
        
    }

    .section__content {
        @media screen and (max-width: $lg) {
            flex-direction: column;
            background-color: $color_black;
        }

        @media screen and (max-height: 759px) and (min-width: $xl) {
            min-height: 880px;
        } 

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
           min-height: auto;
        }

    }

    &__list {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        padding: 0 12vw;
        margin-top: 330px;
        z-index: 4;

        @media screen and (min-width: $fullhd + 1px) {
            margin-top: 37vh;
        } 

        @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 900px) {
            margin-top: 215px;
        }

        @media screen and (max-width: $lg) {
            margin: 40px 0; 
            padding: 0 20px;
            flex-direction: column;
            position: relative;
        }

        @media screen and (min-width: $lg) and (max-width: $xl) {
            margin-top: 197px;
        }

        @media screen and (max-height: 759px) and (min-width: $xl) {
            margin-top: 270px;
        } 

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            margin-top: 165px;
        }
    }

    &__item {
        flex: 1;
        flex-basis: calc(50% - 200px);
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: $xxl) {
            padding-bottom: 30px;
        }

        @media screen and (max-width: 1300px) {
            flex-basis: calc(50% - 80px);
        }
        
        a, p {
            color: $color_black;
        }

        p {
            margin-top: 10px;
        }

        &--white {
            padding-right: 200px;

            @media screen and (max-width: 1366px) {
                padding-right: 80px;
            } 

            a, h4, p {
                color: white;
            }
        }
    }

    &__itemText {
        font-size: 14px;
        line-height: 24px !important;

        @media screen and (min-width: $fullhd + 1px) {
            font-size: 16px;
            line-height: 30px !important;
        }

        @media screen and (max-width: $lg) {
            color: white !important;
            font-size: 16px;
        }
    }

    &__link {
        /* &:hover {
            color: $color_red;
        }

        @media screen and (max-width: $lg) {
            color: white !important
        } */
    }
}
</style>
