<template>
    <div class="section vacancies">
        <div class="section__content">
            <div class="section__left">
                <h2 class="typography typography--h2 section__title">
                    <span class="white">{{ $t('sections.vacancies.Our') }} </span>
                    <span class="red">{{ $t('sections.vacancies.vacancies') }}</span>
                </h2>
                <div class="vacancies__figure"></div>
                <video ref="vacancies__video" autoplay muted loop playsinline class="section__leftVideo">
                  <source src="@/assets/video/glitch1.mp4" type="video/mp4">
                </video>
            </div>
            <div class="section__right">
                <div v-click-outside="outsideVacancyClick" class="vacancies__openVacancy" :class="{'vacancies__openVacancy--show' : isOpenVacancy}">
                    <div v-if="openVacancyId == 0" class="vacancies__openVacancyInner" >
                        <h4 class="typography typography--h4">Middle PHP Developer (Laravel)</h4>
                        <p class="typography vacancies__openVacancyHeader">
                            <b>Job Type</b> : Full-time <br>
                            <b>Job Function</b> : Web Developer <br>
                            <b>Schedule</b> : Monday to Friday
                        </p>
                        <hr>
                        <p class="typography typography--paragraph"><b>Required Skills</b></p>
                        <br>
                        <ul>
                            <li>Expertise in object-oriented programming in PHP</li>
                            <li>Laravel</li>
                            <li>PHP, MySQL, JavaScript, NodeJS, Docker, jQuery</li>
                            <li>Expertise with Javascript, CSS, HTML5</li>
                            <li>Familiarity with source control principles and comfortable with Git</li>
                            <li>Experience using agile development methodology, specifically Scrum</li>
                        </ul>
                        <p class="typography typography--paragraph"><b>Desired Experience:</b></p>
                        <br>
                        <ul>
                            <li>Agile development methodologies</li>
                            <li>1+ years experience in commercial development</li>
                            <li>Understanding of HTML, CSS, JS, JQuery, AWS, Redis</li>
                            <li>Understanding of JS, GitHub, PHP/MySQL Hosting</li>
                        </ul>
                        <br>
                        <p class="typography typography--paragraph">The ideal candidate has an extensive background in software development and is looking to join a creative, fast-paced and close-knit team.</p>
                        <p class="typography typography--paragraph">In this role, the Laravel developer will work closely with the development team to produce, test and release new functionality for large platforms.</p>
                    </div>

                    <div v-if="openVacancyId == 1" class="vacancies__openVacancyInner" >
                        <h4 class="typography typography--h4">Middle PHP Developer (YII2)</h4>
                        <p class="typography vacancies__openVacancyHeader">
                            <b>Job Type</b> : Full-time <br>
                            <b>Job Function</b> : Web Developer <br>
                            <b>Schedule</b> : Monday to Friday
                        </p>
                        <hr>
                        <p class="typography typography--paragraph"><b>Required Skills</b></p>
                        <br>
                        <ul>
                            <li>Expertise in object-oriented programming in PHP</li>
                            <li>YII2</li>
                            <li>PHP, MySQL, JavaScript, NodeJS, Docker, jQuery</li>
                            <li>Expertise with Javascript, CSS, HTML5</li>
                            <li>Familiarity with source control principles and comfortable with Git</li>
                            <li>Experience using agile development methodology, specifically Scrum</li>
                        </ul>

                        <p class="typography typography--paragraph"><b>Desired Experience:</b></p>
                        <br>
                        <ul>
                            <li>Agile development methodologies</li>
                            <li>1+ years experience in commercial development</li>
                            <li>Understanding of HTML, CSS, JS, JQuery, AWS, Redis</li>
                            <li>Understanding of JS, GitHub, PHP/MySQL Hosting</li>
                        </ul>
                        <br>
                        <p class="typography typography--paragraph">The ideal candidate has an extensive background in software development and is looking to join a creative, fast-paced and close-knit team.</p>
                        <p class="typography typography--paragraph">In this role, the YII2 developer will work closely with the development team to produce, test and release new functionality for large platforms.</p>
                    </div>

                    <div v-if="openVacancyId == 2" class="vacancies__openVacancyInner" >
                        <h4 class="typography typography--h4">Front-end developer</h4>
                        <p class="typography vacancies__openVacancyHeader">
                            <b>Job Type</b> : Full-time <br>
                            <b>Job Function</b> : Web Developer <br>
                            <b>Schedule</b> : Monday to Friday
                        </p>
                        <hr>
                        <p class="typography typography--paragraph"><b>Required Skills</b></p>
                        <br>
                        <ul>
                            <li>Vue.js</li>
                            <li>Converting wireframes, prototypes, sketches, and high-fidelity mockups into usable and responsive applications</li>
                            <li>Writing semantic, accessibility minded, and scalable development</li>
                            <li>Writing responsive, organized, and DRY Javascript and Sass</li>
                            <li>Familiarity with source control principles and comfortable with Git</li>
                            <li>Experience using agile development methodology, specifically Scrum</li>
                        </ul>

                        <p class="typography typography--paragraph"><b>Desired Experience:</b></p>
                        <br>
                        <ul>
                            <li>Agile development methodologies</li>
                            <li>1+ years experience in commercial development</li>
                            <li>Understanding of HTML, CSS, JS, JQuery, AWS, Redis</li>
                            <li>Understanding of JS, GitHub, PHP/MySQL Hosting</li>
                        </ul>
                        <br>
                        <p class="typography typography--paragraph">The ideal candidate has an extensive background in software development and is looking to join a creative, fast-paced and close-knit team.</p>
                        <p class="typography typography--paragraph">In this role, the Vue.js developer will work closely with the development team to produce, test and release new functionality for large platforms.</p>
                    </div>

                    <div v-if="openVacancyId == 3" class="vacancies__openVacancyInner" >
                        <h4 class="typography typography--h4">Middle QA Engineer (Manual)</h4>
                        <p class="typography vacancies__openVacancyHeader">
                            <b>Job Type</b> : Full-time <br>
                            <b>Job Function</b> : Web Developer <br>
                            <b>Schedule</b> : Monday to Friday
                        </p>
                        <hr>
                        <p class="typography typography--paragraph"><b>Required Skills</b></p>
                        <br>
                        <ul>
                            <li>Develop acceptance test criteria for our solutions and products</li>
                            <li>Create manual tests to ensure we constantly deliver quality products</li>
                            <li>Develop and monitor performance metrics</li>
                            <li>Analyze bugs and errors found during tests</li>
                            <li>Document and communicate the results of tests to the engineering team</li>
                            <li>Recommend improvements in software to enhance user experience</li>
                            <li>Monitor the development process for efficiency and performance</li>
                        </ul>
                        <p class="typography typography--paragraph"><b>Desired Experience:</b></p>
                        <br>
                        <ul>
                            <li>2+ year(s) of experience in a quality assurance role</li>
                            <li>Understand core software testing concepts, paradigms, and approaches.</li>
                            <li>Experience in writing clear, concise and comprehensive test plans</li>
                            <li>Experience working in an agile/scrum environment</li>
                            <li>Experience writing automated tests with Selenium or other testing tools</li>
                        </ul>
                    </div>

                    <div class="vacancies__openVacancyActions">
                        <ButtonComponent class="vacancies__openVacancyRespond" :to="'mailto: office@sterkonix.cz'" :caption="$t('sections.vacancies.Respond')"/>
                        <ButtonComponent @click="hideVacancy" class="vacancies__openVacancyHide" :caption="$t('sections.vacancies.Hide')" />
                    </div>
                </div>
            </div>

            <div class="vacancies__list" :class="{'vacancies__list--hide' : isOpenVacancy}">

                <div @click="viewVacancy(index)" v-for="(v, index) in vacanciesList" :key="index" class="vacancies__item"
                    :class="{'vacancies__item--dark': index % 2 === 0 || isMobile, 'vacancies__item--light': index % 2 !== 0 && !isMobile }">
                    <h4 class="typography typography--h4">{{ v.title }}</h4>
                    <ul>
                        <li v-for="(li, id) in v.resp" :key="id">{{ li }}</li>
                    </ul>
                </div>

            </div>

        </div>
    </div>
</template>

<script>

import ClickOutside from 'vue-click-outside'

export default {

    directives: {
        ClickOutside
    },

    data() {
        return {
            isOpenVacancy: false,
            openVacancyId: null,
            vacanciesList: [
                {
                    title: this.$t('sections.vacancies.list[0].title'),
                    resp: [
                        this.$t('sections.vacancies.list[0].resp[0]'),
                        this.$t('sections.vacancies.list[0].resp[1]'),
                        this.$t('sections.vacancies.list[0].resp[2]'),
                    ]
                },
                {
                    title: this.$t('sections.vacancies.list[1].title'),
                    resp: [
                        this.$t('sections.vacancies.list[1].resp[0]'),
                        this.$t('sections.vacancies.list[1].resp[1]'),
                        this.$t('sections.vacancies.list[1].resp[2]'),
                    ]
                },
                {
                    title: this.$t('sections.vacancies.list[2].title'),
                    resp: [
                        this.$t('sections.vacancies.list[2].resp[0]'),
                        this.$t('sections.vacancies.list[2].resp[1]'),
                        this.$t('sections.vacancies.list[2].resp[2]'),
                    ]
                },
                {
                    title: this.$t('sections.vacancies.list[3].title'),
                    resp: [
                        this.$t('sections.vacancies.list[3].resp[0]'),
                        this.$t('sections.vacancies.list[3].resp[1]'),
                        this.$t('sections.vacancies.list[3].resp[2]'),
                    ]
                }
            ],
        }
    },

    methods: {
        viewVacancy(id) {
            this.openVacancyId = id
            this.isOpenVacancy = true;
            if (this.isMobile) {
                $('body').css('overflow', 'hidden')
            }
        },
        hideVacancy() {
            this.isOpenVacancy = false
            $('body').css('overflow', 'auto')
        },
        outsideVacancyClick() {
            if (this.isMobile) {
                this.isOpenVacancy = false
                $('body').css('overflow', 'auto')
            }
        }
    }
};
</script>

<style lang="scss" scoped>

.vacancies {

    @media screen and (max-width: $lg) {
        position: relative;
        z-index: 100;

        .section__right {
            height: 0;
            position: fixed;
            left: 20px;
            top: 90px;
            width: calc(100vw - 40px);
            z-index: 11;
        }

        .section__content {
            padding: 80px 0;
            flex-direction: column;
            background-color: $color_black;

        }
    }

    @media screen and (max-height: 759px) and (min-width: $xl) {
        .section__content {
            min-height: 880px;
        }
    }

    @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
        .section__content {
            min-height: auto;
        }
    } 

    &__list {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        padding: 0 12vw;
        margin-top: 330px;
        margin-left: -100px;
        margin-right: -100px;
        z-index: 2;


        @media screen and (min-width: $fullhd + 1px) {
            margin-top: 37vh;
        }

        @media screen and (max-width: $xxl) {
            padding: 0 calc(12vw - 80px);
            margin-left: 0;
            margin-right: 0;
            box-sizing: border-box;
        }

        @media screen and (max-width: $xl) {
            margin-top: 315px; 
        }

        @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 900px) {
            margin-top: 240px;
        }

        @media screen and (max-width: $lg) {
            position: relative;
            padding: 0 20px;
            margin: 0;
            padding-top: 40px;
            z-index: 20;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            margin-top: 160px;
        }

        &--hide {
            z-index: 1;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        padding: 30px 40px;
        border-bottom: 1px solid $color_red;
        transition: .2s;
        flex: 1;
        flex-basis: 25%;
        margin: 0 100px;
        margin-top: 40px;

        &:nth-child(1), &:nth-child(2) {
            margin-top: 0;
        }

        @media screen and (max-width: 1500px) {
            margin: 0 40px;
            margin-top: 40px;
        }

        @media screen and (max-width: $xl) {
            margin: 0;
            margin-top: 40px;
            flex-basis: 40%;
        }


        @media screen and (max-width: $xxl) and (min-width: $lg) {
            flex-basis: calc(50% - 200px);
        }


        @media screen and (max-width: $lg) {
            flex-basis: 100%;
            margin: 0 !important;
            margin-top: 10px !important;
            padding: 20px;
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            margin-top: 20px;
        }

        h4 {
            padding-bottom: 25px;
        }

        li + li {
            margin-top: 15px;
        }

        li {
            position: relative;
            padding-left: 20px;
            font-size: 14px;

            &:before {
                position: absolute;
                content: '';
                left: 0;
                top: 45%;
                transform: translate(0, -50%);
                border-radius: 50%;
                height: 4px;
                width: 4px;
                background-color: $color_red;
            }

            @media screen and (min-width: $fullhd + 1px) {
                font-size: 18px;
            }

            @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
                font-size: 12px;
            }
        }

        &:hover {
            cursor: pointer;
            background-color: #96253e;

            h4, li {
                color: white;
            }
        }

        &--dark {
            background-color: #41445299;

            @media screen and (max-width: $xxl) and (min-width: $lg) {
                margin-right: 100px;
            }

            h4, li {
                color: white;
            }
        }

        &--light {
            background-color: #f8f9fa;

            h4, li {
                color: $color_black;
            }
        }
    }

    &__openVacancy {
        opacity: 0;
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: white;
        margin-top: 330px;
        height: calc(100vh - 390px);
        overflow: hidden;
        transition: .2s;
        z-index: -1;
        

        @media screen and (max-width: $xl) {
            height: 550px;
        } 

        @media screen and (max-width: $lg) {
            box-shadow: 0 3px 40px #b10d26;
            border-radius: 25px;
            height: 0;
            padding: 0;
            margin: 0;
        }

        @media screen and (max-width: $xxl) and (min-width: $lg) {
            padding-bottom: 80px;
        }

        @media screen and (max-width: $sm) {
            padding: 30px 20px;
        }

        @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 900px) {
            margin-top: 240px;
            height: calc(100vh - 240px);
        }

        @media screen and (max-width: $xxl) and (max-height: 740px) and (min-width: 1200px) and (min-height: 595px) {
            margin-top: 160px;
            height: calc(100vh - 160px);
        }

        li {
            position: relative;
            padding-left: 20px;
            color: $color_black;
            font-weight: 400;

            &:before {
                position: absolute;
                content: '';
                left: 0;
                top: 45%;
                transform: translate(0, -50%);
                border-radius: 50%;
                height: 4px;
                width: 4px;
                background-color: $color_red;
            }
        }

        .typography--paragraph {
            font-size: 16px !important;
            line-height: 26px !important;
        }

        li + li {
            margin-top: 10px;
        }

        hr {
            width: 100%;
            height: 1px;
            border: none;
            background: #dce3e4;
            margin: 20px 0;
        }

        &--show {
            opacity: 1;
            z-index: 4;

            @media screen and (max-width: $lg) {
                height: calc(100vh - 250px);
                padding: 40px 0 70px;

                .vacancies__openVacancyInner {
                    padding: 0 30px;
                    padding-bottom: 40px;
                }

                .typography {
                    color: $color_black
                }
            }
        }
    }

    &__openVacancyInner {
        overflow: auto;
    }

    &__openVacancyHeader {
        padding-top: 20px;
        line-height: 30px;
    }

    &__figure {
        background-image: url("~@/assets/img/vacancies.png");
        background-position: center;
        opacity: .6;
        background-size: cover;
        height: 600px;
        width: 600px;
        left: calc(50vw - 400px);
        bottom: -263px;
        z-index: 2;
        position: absolute;
        background-repeat: no-repeat;

        @media screen and (max-width: $lg) {
            z-index: 1;
            opacity: .3;
            bottom: -64px;
        }

        @media screen and (max-width: $sm) {
            opacity: .3;
        }
    }

    &__openVacancyDate {
        color: $color_red;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 900;
        letter-spacing: 1.74px;
    }

    &__openVacancyActions {
        display: flex;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 40px;

        @media screen and (max-width: $lg) {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            margin: 0;

            .button {
                width: 50%;
            }
        }

        @media screen and (max-width: $sm) {
            flex-wrap: wrap;

            .button {
                width: 100%;
            }
        }

        
    }

    &__openVacancyHide {
        background-color: white;
        color: $color_black;
        border-color: white;

        &:hover {
            color: $color_black;
            background-color: #f7f7f7;
            border-color: #f7f7f7;
            box-shadow: none;
        }
    }

    @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 900px) {
        .section__left {
            padding-top: 70px;
        }
    }


}
</style>
